<div class="app-faq">
    <div class="section-1">
        <div class="background">
            <div class="container">
                <!-- <div class="header-container">
                    <img class="care-logo" src="https://assets.carefinance.xyz/Careblacklogo.png" alt="Care Finance"
                        title="Care Finance" />

                    <h1 (click)="scrollToSection()" class="title">FAQ</h1>
                </div> -->
                <div class="main-container">
                    <div class="text-content">
                        <h2 class="title-white">Frequently Asked</h2>
                        <h2 class="title-white">Questions</h2>
                        <p class="sub-title">Do you have any questions? Here are some frequently asked questions that we
                            have
                            answered</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="section-4" id="target-section">
        <div class="container">

            <div *ngFor="let section of faqs">
                <div class="faq-text-content">
                    <h2 class="faq-title">{{section.title}} </h2>
                </div>
                <div class="sub-container">
                    <div class="faq-cards">
                        <div class="faq-card" *ngFor="let faq of section.faqs" (click)="openFaq(faq.index)">
                            <div class="faq-question">
                                <span>{{ faq.question }}</span>
                                <i class="fa-solid fa-minus" *ngIf="faq.index === openedFaqIndex"></i>
                                <i class="fa-solid fa-plus" *ngIf="faq.index !== openedFaqIndex"></i>
                            </div>
                            <span class="faq-answer" *ngIf="faq.index === openedFaqIndex">{{ faq.answer }}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>