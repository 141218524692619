<div class="app-policy">
    <div class="section-1">
        <div class="background">
            <div class="container">
                <!-- <div class="header-container">
                    <img class="care-logo" src="https://assets.carefinance.xyz/Careblacklogo.png" alt="Care Finance"
                        title="Care Finance" />

                    <h1 (click)="scrollToSection()" class="title">FAQ</h1>
                </div> -->
                <div class="main-container">
                    <div class="text-content">
                        <h2 class="title-white">Policies</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="section-2">
        <div class="container">
            <ol>
                <li>
                    <!-- <p (click)="redirectToUrl('policies/interest-rate-policy')"> -->
                    <p routerLink="/policies/interest-rate-policy">

                        Interest Rate Policy
                    </p>
                </li>
                <!-- <li>
                    <p>
                        DLG Disclosure
                    </p>

                </li> -->
                <li>
                    <p routerLink="/policies/terms-condition">

                        Terms of use
                    </p>
                </li>
                <li>
                    <p routerLink='/policies/privacy-policy'>

                        Privacy policy
                    </p>
                </li>
                <li>

                    <p routerLink="/policies/face-code-of-conduct-policy">

                        FACE code of conduct

                    </p>
                </li>
                <li>
                    <p routerLink="/policies/dlai-code-of-conduct-policy">DLAI code of conduct</p>
                </li>
                <li>
                    <p routerLink="/policies/grievance-redressal-policy">Grievance redressal mechanism
                        policy</p>
                </li>
                <li>
                    <p routerLink="/policies/fair-practice-code-policy">Fair practice code</p>
                </li>
            </ol>
        </div>
    </div>
</div>