import {
    Component, OnInit 
} from '@angular/core';

@Component({
    selector: 'app-dlai-code-conduct-policy',
    templateUrl: './dlai-code-conduct-policy.component.html',
    styleUrls: [ './dlai-code-conduct-policy.component.scss' ]
})
export class DLAICodeConductPolicyComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }
}
