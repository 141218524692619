import {
    Component, OnInit 
} from '@angular/core';

@Component({
    selector: 'app-face-code-conduct-policy',
    templateUrl: './face-code-conduct-policy.component.html',
    styleUrls: [ './face-code-conduct-policy.component.scss' ]
})
export class FaceCodeConductPolicyComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }
}
