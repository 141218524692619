<div class="app-homepage">
    <div class="section-1">
        <div class="background">
            <div class="container">
                <!-- <div class="header-container">
                    <img class="care-logo" src="https://assets.carefinance.xyz/Carewhitelogo.png" alt="Care Finance"
                        title="Care Finance" />

                    <h1 (click)="scrollToSection()" class="title">FAQ</h1>
                </div> -->
                <div class="main-container">
                    <div class="text-content">
                        <!-- <div class="trust-container">
                            <img class="avatar-img" src="https://assets.carefinance.xyz/Frame12.png" alt="Care Finance"
                                title="Care Finance" />
                            <span class="title">Trusted by 20,000+ people</span>
                        </div> -->
                        <h1 class="title">Healthcare</h1>
                        <h2 class="title-white">Finance For You</h2>
                        <p class="sub-title mt-2">Access medical care instantly with CareFinance’s cashless payment
                            solution on your mobile.
                        </p>
                        <div class="mt-3 mb-4">
                            <cf-button (click)="scrollDown()" label="Borrow now" type="theme"></cf-button>
                        </div>
                        <div class="register-content">
                            <span class="register-title">Registered NBFC under RBI</span>
                            <img class="rbi-img" src="https://assets.carefinance.xyz/image12.png" alt="Care Finance"
                                title="Care Finance" />
                        </div>
                        <div class="store-content">
                            <img (click)="scrollDown()" class="store-img"
                                src="https://assets.carefinance.xyz/playstore.png" alt="Care Finance"
                                title="Care Finance" />
                            <img (click)="scrollDown()" class="store-img"
                                src="https://assets.carefinance.xyz/appstore.png" alt="Care Finance"
                                title="Care Finance" />
                        </div>
                    </div>
                    <div class="phone-img-content">
                        <img class="phone-img" src="https://assets.carefinance.xyz/handmobile.png" alt="Care Finance"
                            title="Care Finance" />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="section-2">
        <div class="container">
            <div class="card-container">
                <div class="card">
                    <img class="card-img" src="https://assets.carefinance.xyz/graphh.png" alt="Care Finance"
                        title="Care Finance" />
                    <span class="card-title">Build your financial profile as you access care</span>
                    <span class="card-sub-title">Our BNPL process is designed with your growth in mind. As you use
                        CareFinance to manage medical expenses, you build your credit, giving you access to larger
                        amounts for future healthcare needs.</span>
                </div>
                <div class="card">
                    <img class="card-img" src="https://assets.carefinance.xyz/mobile.png" alt="Care Finance"
                        title="Care Finance" />
                    <span class="card-title">Seamless healthcare financing on the go</span>
                    <span class="card-sub-title">Unlike traditional lenders, CareFinance doesn’t require tedious
                        paperwork or lengthy approval processes. Just apply through our app and get approved in less
                        than 24 hours, so you can focus on your health, not financial stress.</span>
                </div>
            </div>
            <div class="slider-container">
                <div class="mobile-img-container">
                    <img class="slider-img" [src]="slides[currentSlide].image" alt="Care App" />
                </div>
                <div class="slider-text-container">
                    <div class="slider-numbers">
                        <span *ngFor="let slide of slides; let i = index" [class.active]="i === currentSlide">{{ i + 1
                            }}</span>
                    </div>
                    <div class="slider-content">
                        <button class="arrow-btn" (click)="prevSlide()">&#x276E;</button>
                        <div class="text-container">
                            <h1 class="slider-description">{{ slides[currentSlide].text }}</h1>
                        </div>
                        <button class="arrow-btn" (click)="nextSlide()">&#x276F;</button>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="section-3">
        <div class="container">
            <h1 class="title">Privacy is paramount</h1>
            <p class="sub-title">At CareFinance, the privacy and security of our customers and their data is our top
                priority.</p>
            <div class="sub-container">
                <div class="text-content">
                    <div class="text-section1">
                        <div class="main-content">
                            <img class="content-img" src="https://assets.carefinance.xyz/Maskgroup1.png"
                                alt="Care Finance" title="Care Finance" />
                            <h1 class="title">1. Data Encryption</h1>
                            <p class="sub-title">CareFinance uses advanced encryption technology to safeguard all
                                customer data, ensuring it stays secure both during transmission and when stored.</p>
                        </div>
                        <div class="main-content">
                            <img class="content-img" src="https://assets.carefinance.xyz/Maskgroup2.png"
                                alt="Care Finance" title="Care Finance" />
                            <h1 class="title">2. User Consent</h1>
                            <p class="sub-title">We are committed to transparency. We collect, process, and share
                                personal information only after receiving clear and explicit consent from our users,
                                giving you full control over your data.</p>
                        </div>
                    </div>
                    <div class="text-section2">
                        <div class="main-content">
                            <img class="content-img" src="https://assets.carefinance.xyz/Maskgroup.png"
                                alt="Care Finance" title="Care Finance" />
                            <h1 class="title">3. Minimal Data Collection</h1>
                            <p class="sub-title">CareFinance minimizes the data we collect, only gathering what’s
                                necessary to provide our services.</p>
                        </div>
                        <div class="main-content">
                            <img class="content-img" src="https://assets.carefinance.xyz/Maskgroup3.png"
                                alt="Care Finance" title="Care Finance" />
                            <h1 class="title">4. Secure Authentication</h1>
                            <p class="sub-title">Our platform complies with global privacy regulations like GDPR and
                                CCPA, as well as local data protection laws, to ensure your information is always
                                handled with the highest standards of care.</p>
                        </div>
                    </div>
                </div>
                <div class="img-content">
                    <img class="women-img" src="https://assets.carefinance.xyz/businesswomann.png" alt="Care Finance"
                        title="Care Finance" />
                </div>
            </div>
        </div>
    </div>





</div>