<div class="app-fair-practice-code-policy">

    <div class="content">
        <!-- <div class="left-section">
            <div class="logo">
                <img src="https://assets.carefinance.xyz/Carewhitelogo.png" alt="Care Finance" title="Care Finance"
                    loading="lazy" />
            </div>
        </div> -->

        <div class="privacy-content">

            <h1 class="heading">Carefinance International Fair Practices Code</h1>
            <br>
            <br>
            <div class="paragraph">

                <ol>
                    <h4>CareFinance Healthcare App </h4>

                    <li>
                        <p class="bold">Introduction</p>
                        <p>This Fair Practices Code (‘Code’) has been formulated and adopted by Cipherhut software India
                            Pvt Ltd DBA
                            Carefinance (“Company”) pursuant to Para 45 of the Master Direction – Reserve Bank of India
                            (Non-Banking
                            Financial Company – Scale Based Regulation) Directions, 2023 (“SBR Master Directions”). The
                            Company shall make
                            appropriate modifications to this Code from time to time to conform to the standards that
                            may be prescribed by
                            the Reserve Bank of India (RBI) from time to time.</p>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Objectives</p>
                        <ol>
                            <li class="hide">
                                2.1. Follow good, fair and transparent business practices by setting reasonable
                                standards
                                in
                                dealing with
                                customers;</li>
                            <li class="hide">
                                2.2. Encourage market forces, through fair competition, to achieve higher operating
                                standards;</li>
                            <li class="hide">
                                2.3. Relate to the customer in such a manner so as to promote a fair and cordial
                                relationship;</li>
                            <li class="hide">
                                2.4. Conduct recovery and enforcement, where necessary, following due process of law.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Definition</p>
                        <p>For the purpose of this Policy, a Customer is defined as any person who has availed or is
                            availing at least one
                            of the loan products of the Company.</p>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Application</p>
                        <ol>
                            <li class="hide">
                                4.1. To be applicable to all persons offering the products and services of the Company
                                or
                                interacting with the
                                customers as an employee or otherwise in any manner and/or by any mode.</li>
                            <li class="hide">
                                4.2. The Code is applicable under a normal operating environment except in the
                                event of any
                                force majeure.</li>
                            <li class="hide">
                                4.3. The Code is based on ethical principles of integrity and transparency and
                                all actions
                                and dealings shall
                                follow the spirit of the Code.</li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Commitment</p>
                        <ol>
                            <li class="hide">5.1. The Company shall at all times do its best to act fairly, reasonably,
                                and
                                meet the
                                standard practices
                                prevalent in the industry in which it operates.</li>
                            <li class="hide">5.2. The Company shall abide by all the relevant laws, regulations, and
                                meet the
                                ethical
                                principles of integrity
                                and transparency during its interaction with customers.</li>
                            <li class="hide">5.3. The Company will not discriminate on the grounds of gender, caste, and
                                religion in the
                                matter of lending.
                                Further, the Company shall not discriminate in extending its products and facilities,
                                including loan
                                facilities, to physically/visually challenged applicants on grounds of disability.</li>
                            <li class="hide">5.4.The Company shall treat the information or data relating to customers
                                as
                                strictly
                                confidential and shall not
                                share any information or data except as explicitly explained under our Privacy Policy,
                                unless required under
                                law or waived or permitted by the customer, or under the following exceptional cases:
                                <ol>
                                    <li class="hide">i. If there is a duty to the public to reveal the information.</li>
                                    <li class="hide">ii. If the Company’s interest requires it to provide this
                                        information (e.g.,
                                        fraud
                                        prevention) to
                                        Banks/Financial institutions/Groups/Associate Companies.</li>
                                </ol>
                            </li>
                            <li class="hide">The Company shall collect and use information relating to the Customer
                                strictly for loan
                                transactions, and
                                it can be disclosed to a third party only after due consent from the Customer has been
                                received except in
                                the cases mentioned under para 5.4. Information shared with third parties shall be
                                need-based and with prior
                                and explicit consent of the Customer.</li>
                            <li class="hide">The Company shall take necessary steps to inform its customers of their
                                right to
                                information regarding their
                                account and the facilities available to them.</li>
                            <li class="hide">The Company shall be clear and not misleading in any of its advertising and
                                promotional
                                materials.</li>
                            <li class="hide">The Company shall inform its customers of all financial information such as
                                rates of
                                interest, charges, and
                                the method of calculation through KFS, brochures, posters, or during the course of
                                meeting with the
                                customers prior to entering into any transaction.</li>
                            <li class="hide">The Company shall give notice to the Customer in the vernacular language or
                                a language
                                understood by the
                                Customer of any change in the terms and conditions, including disbursement schedule,
                                interest rates, service
                                charges, prepayment charges, etc.</li>
                            <li class="hide">The Company shall disclose, by such mode and in such manner as deemed fit
                                to ensure
                                transparency, all
                                information affecting the interest of the Customer, including but not limited to:
                                <ol>
                                    <li class="hide">i. Fees/charges payable for processing the loan application;</li>
                                    <li class="hide">ii. Annualized rate of interest & method of application thereof;
                                    </li>
                                    <li class="hide">iii. The amount of fee refundable, if any, if the loan amount is
                                        not sanctioned;</li>
                                    <li class="hide">iv. Prepayment options and charges, if any;</li>
                                    <li class="hide">v. Penalty for delayed repayment, in bold, if any;</li>
                                    <li class="hide">vi. Conversion charges, if any (Switching loan from fixed to
                                        floating rate or
                                        vice–versa);</li>
                                    <li class="hide">vii. Existence of interest re-set clause, if any;</li>
                                    <li class="hide">viii. Any other matter which affects the interest of the Customer.
                                    </li>
                                </ol>
                            </li>
                            <li class="hide">The disclosure shall be done so as to ensure that the Customers are aware
                                of all
                                interest, charges, and fees
                                involved in processing and sanctioning of the loan. The Company shall not indulge in any
                                act which is
                                discriminatory among equals.</li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Loan Application and Credit Appraisal</p>
                        <ol>
                            <li class="hide">6.1. The Company in the normal course of its business shall endeavor at all
                                times to guide
                                its customers about
                                the process and procedure to be followed for availing a loan.</li>
                            <li class="hide">6.2. Each application shall be considered independently on merit, upon
                                scrutiny of all the
                                information, identity
                                of the person and/or entity.</li>
                            <li class="hide">6.3. The Company shall carry out due diligence on the Customer to ascertain
                                the
                                creditworthiness of the Customer,
                                which will be an important parameter in making a decision on the application
                                before sanctioning or rejecting
                                any loan application. In accordance with the prescribed risk-based assessment procedures
                                and the Credit
                                Policy of the Company, each application will be assessed based on such risk assessment
                                and our prevailing
                                guidelines. The Company shall consider and capture the economic profile of the Customer
                                covering (age,
                                occupation, income, etc.), before extending any loan, with a view to assessing the
                                Customer’s
                                creditworthiness in an auditable way.</li>
                            <li class="hide">6.4. The Company shall ensure that there is no automatic increase in credit
                                limit unless
                                explicit consent of the
                                Customer is taken on record for each such increase.</li>
                            <li class="hide">6.5. The Company shall give an acknowledgement for receipt of all loan
                                applications. The
                                likely time frame within
                                which loan applications will be disposed of shall also be indicated in the
                                acknowledgement.</li>
                            <li class="hide">6.7. The loan application form shall include necessary information which
                                affects the interest
                                of the Customer so
                                as to enable the Customer to do a meaningful comparison of terms and conditions offered
                                by other NBFCs and
                                make an informed decision.</li>
                            <li class="hide">6.8. The Company shall convey in writing to the Customer in English or any
                                other vernacular
                                language as
                                understood by the Customer, by means of a sanction letter, loan agreement or otherwise,
                                the amount of loan
                                sanctioned along with the terms and conditions including annualized rate of interest and
                                method of
                                application thereof and keep the acceptance of these terms and conditions by the
                                Customer on its record.
                            </li>
                            <li class="hide">6.9. The Company shall provide a Key Fact Statement (KFS) to the Customer
                                in a standardized
                                format for all
                                digital lending products. Any fees, charges, etc., which are not mentioned in the KFS
                                cannot be charged by
                                the REs to the Customer at any stage during the term of the loan.</li>
                            <li class="hide">6.10. All disbursements, repayment, etc., shall be executed by the Customer
                                directly in the
                                bank account of the
                                Company without any pass-through account/pool account of any third party. The
                                disbursements shall also
                                always be made into the bank account of the Customer except for disbursals covered
                                exclusively under
                                statutory or regulatory mandate.</li>
                            <li class="hide">6.11. The loan application form shall also indicate the documents required
                                to be submitted
                                with the application
                                form.</li>
                            <li class="hide">6.12. The repayment schedule shared with the Customer shall clearly
                                indicate the bifurcation
                                between interest and
                                principal as provided in the loan documentation.</li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">7. Loan Agreement and Documentation</p>
                        <ol>
                            <li class="hide">7.1. The Company shall mention the penal charges charged, if any, for late
                                repayment in bold
                                in the loan
                                agreement.</li>
                            <li class="hide">7.2. The Company shall clearly specify the exact due dates for repayment of
                                a loan, frequency
                                of repayment,
                                breakup between principal and interest, examples of SMA/NPA classification dates, etc.
                                in the loan agreement
                                and the Customer shall be apprised of the same at the time of loan sanction and also at
                                the time of
                                subsequent changes, if any, to the sanction terms/loan agreement till full repayment of
                                the loan. In cases
                                of loan facilities with moratorium, the exact date of commencement of repayment shall
                                also be specified in
                                the loan agreements.</li>
                            <li class="hide">7.3. Any fees, charges, etc., which are not mentioned in the loan
                                documentation shall not be
                                charged by the
                                Company to the Customer at any stage during the term of the loan.</li>
                            <li class="hide">7.4. The Company shall ensure that changes in interest rates and charges
                                are effective from a
                                prospective date. A
                                suitable condition in this regard shall be incorporated in the loan agreement.</li>
                            <li class="hide">7.5. The decision to recall/accelerate payment or performance under the
                                agreement shall be in
                                consonance with the
                                loan agreement.</li>
                            <li class="hide">7.6. The Company shall furnish via downloadable link, a copy of the loan
                                agreement as
                                understood by the Customer
                                along with a copy of all enclosures quoted in the loan agreement to all the Customers at
                                the time of
                                sanction/disbursement of loans.</li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Interest Rate and Other Charges</p>
                        <ol>
                            <li class="hide">8.1. The Company has adopted an interest rate model taking into account
                                relevant factors such
                                as cost of funds,
                                margin and risk premium in determining the rate of interest to be charged for loans and
                                advances.</li>
                            <li class="hide">8.2. The rate of interest and the approach for gradations of risk and
                                rationale for charging
                                different rates of
                                interest to different categories of Customers shall be disclosed to the Customer in the
                                application form and
                                communicated explicitly in the sanction letter through the mobile-based application and
                                shall also be made
                                available on the website of the Company or published in the relevant newspapers, as the
                                Company may decide.
                                The information published on the website of the Company or otherwise published shall
                                also be updated
                                whenever there is a change in the rate of interest.</li>
                            <li class="hide">8.3. The Company shall endeavor to keep its customers informed of any
                                change in interest
                                rates/charges, etc.
                                through letters or any other form of general or public announcement or displays, from
                                time to time.</li>
                            <li class="hide">8.4. The Board of Directors of the Company shall lay out appropriate
                                internal principles and
                                procedures in
                                determining interest rates and processing and other charges. The manner, types, and
                                rates of charges to be
                                levied on a Customer shall be as per the Company’s Interest Rate and Other Charges
                                Policy.</li>
                            <li class="hide">8.5. Penalty, if charged, for non-compliance of material terms and
                                conditions of the loan
                                contract by the
                                Customer shall be treated as ‘penal charges’ and shall not be levied in the form of
                                ‘penal interest’ that is
                                added to the rate of interest charged on the advances. There shall be no capitalization
                                of penal charges
                                i.e., no further interest computed on such charges.</li>
                            <li class="hide">8.6. The quantum of penal charges shall be reasonable and commensurate with
                                the
                                non-compliance of material terms
                                and conditions of the loan contract without being discriminatory within a particular
                                loan/product category.
                            </li>
                            <li class="hide">8.7. The quantum and reason for penal charges shall be clearly disclosed by
                                the Company to
                                the Customers in the
                                loan agreement, Key Fact Statement (KFS), and on the website of the Company (under
                                interest rates and
                                service charges).</li>
                            <li class="hide">8.8. Whenever reminders for non-compliance of material terms and conditions
                                of loan are sent
                                to Customers, the
                                penal charges shall be communicated. Further, any instance of levy of penal charges and
                                the reason therefore
                                shall also be communicated.</li>
                            <li class="hide">8.9. The penal charges in case of loans sanctioned to individual Customers
                                shall not be
                                higher than the penal
                                charges to non-individual Customers for similar non-compliance of material terms and
                                conditions.</li>
                            <li class="hide">8.10. The Company shall not charge foreclosure charges/prepayment penalties
                                on all floating
                                rate term loans
                                sanctioned for purposes other than business to individual Customers, with or without
                                co-obligants.</li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Collection of Dues</p>
                        <ol>
                            <li class="hide">9.1. The Company shall provide the customers with all the information
                                regarding their dues
                                and provide reasonable
                                time for payment of the same.</li>
                            <li class="hide">9.2. The Company shall, while protecting its interest, adopt reasonable and
                                lawful measures
                                to recover its dues
                                from defaulting customers, including use of persuasive methods for the purpose of
                                collection of its dues,
                                and shall operate within the legal framework by RBI and in accordance with the
                                Collection Policy of the
                                Company.</li>
                            <li class="hide">9.3. The Company's collection practices are built on courtesy, fair
                                treatment and persuasion.
                                In the matter of
                                recovery of loans, the Company will not resort to undue harassment viz. Persistently
                                bothering the Customers
                                at odd hours, use of muscle power for recovery of loans, etc. The Company shall ensure
                                that the staff are
                                adequately trained to deal with the Customers in an appropriate manner.</li>
                            <li class="hide">9.4. The Customer shall be informed, at the time of sanctioning of the loan
                                and also at the
                                time of passing on
                                the recovery responsibilities to an LSP or change in the lending service provider
                                responsible for recovery,
                                the details of the lending service provider acting as a recovery agent who is authorized
                                to approach the
                                Customer for recovery.</li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">General</p>
                        <ol>
                            <li class="hide">10.1. While interacting with customers, the Company shall take all steps as
                                may
                                be required to
                                provide clear
                                information either in English, Hindi, or the appropriate vernacular language or a
                                language as understood by
                                the Customer regarding:
                                <ol>
                                    <li class="hide">i. Its various products and services;</li>
                                    <li class="hide">ii. The terms and conditions, the interest rates/service charges;
                                    </li>
                                    <li class="hide">iii. Benefits available to customers and the implications, if any;
                                    </li>
                                    <li class="hide">iv. Contact persons for addressing the queries, if any;</li>
                                    <li class="hide">v. Communication with the customers, if any.</li>
                                </ol>
                            </li>
                            <li class="hide">10.2. The Company shall refrain from interference in the affairs of the
                                Customer
                                except for
                                the purposes provided
                                in the terms and conditions of the loan agreement (unless information, not earlier
                                disclosed by the
                                Customer, has been noticed).</li>
                            <li class="hide">10.3. In case of receipt of a request from the Customer for transfer of a
                                borrowal account,
                                the consent or
                                otherwise i.e., objection of the Company, if any, shall be conveyed within 21 days from
                                the date of receipt
                                of the request. Such a transfer shall be as per transparent contractual terms in
                                consonance with law.</li>
                            <li class="hide">10.4. The Company will provide a copy of this Code, upon request, to the
                                Customer. The Code
                                will also be made
                                available on its Website.</li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Responsibility of the Board of Directors</p>
                        <ol>
                            <li class="hide">11.1. The Board of Directors of the Company has laid down
                                a Grievance Redressal Mechanism within the organization to resolve complaints and
                                grievances which is enunciated in the
                                Grievance Redressal Policy for the Organization.</li>
                            <li class="hide">11.2. This mechanism ensures that all disputes arising out of the decisions
                                of the Company’s
                                functionaries are heard and
                                disposed of at least at the next higher level.</li>
                            <li class="hide">11.3. The Board of Directors shall also provide for a periodical review of
                                the compliance of
                                the Fair Practices Code and
                                the functioning of the grievances redressal mechanism at various levels of management. A
                                consolidated report of such
                                reviews shall be submitted to the Board on an annual basis.</li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Complaints and Grievances</p>
                        <ol>
                            <li class="hide">12.1. The Board of Directors of the Company has laid down an appropriate
                                grievance redressal
                                mechanism within the
                                organization. Such a mechanism ensures that all disputes arising out of the decisions of
                                the Company’s
                                functionaries are heard and disposed of at least at the next higher level.</li>
                            <li class="hide">12.2. The Company shall endeavor to address/respond and dispose of all
                                complaints and
                                grievances within 30 days
                                from receipt of the complete details in respect of the grievance and keep the customers
                                informed about the
                                status of their complaints.</li>
                            <li class="hide">12.3. The detailed provisions for grievance redressal have been included in
                                the Grievance
                                Redressal Mechanism
                                Policy adopted by the Company.</li>
                            <li class="hide">12.4. The Company will ensure that its grievance redressal procedure is
                                made available on its
                                website.</li>
                            <li class="hide">12.5. The Company shall not discriminate in extending products and
                                facilities including loan
                                facilities to
                                physically/visually challenged applicants on grounds of disability. Further, the Company
                                shall ensure
                                redressal of grievances of persons with disabilities under the existing Grievance
                                Redressal Mechanism
                                provided herein.</li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Training and Awareness</p>
                        <ol>
                            <li class="hide">13.1. It shall be ensured that the staff of the Company or any third party
                                is adequately
                                trained to deal with the
                                Customers in an appropriate manner. Training to employees shall include programs to
                                inculcate appropriate
                                behavior towards customers. The conduct of employees towards customers shall also be
                                incorporated
                                appropriately in their compensation matrix.</li>
                            <li class="hide">13.2. The Company shall place consumer education literature on its
                                websites, explaining with
                                examples, the
                                concepts of date of overdue, SMA and NPA classification, and upgradation, with specific
                                reference to the
                                day-end process with a view to increasing awareness among the Customers. The Company
                                shall also display such
                                consumer education literature in its Carefinances by means of posters and/or other
                                appropriate media.
                                Further, the Company shall also ensure that its front-line officers educate Customers
                                about all these
                                concepts, with respect to loans availed by them, at the time of
                                sanction/disbursal/renewal of loans.</li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Review of the Code</p>
                        <ol>
                            <li class="hide">14.1. The Board of Directors shall conduct an annual review of the
                                compliance of this Fair
                                Practices Code and the
                                functioning of the grievance redressal mechanism at various levels of management. The
                                Grievance Redressal
                                Officer (GRO) shall periodically review the compliance of this Code and a consolidated
                                report of such review
                                may be submitted to the Board.</li>
                        </ol>
                    </li>









                </ol>
            </div>
        </div>
    </div>

</div>