<div class="app-policy">
    <div class="section-1">
        <div class="background">
            <div class="container">
                <!-- <div class="header-container">
                    <img class="care-logo" src="https://assets.carefinance.xyz/Careblacklogo.png" alt="Care Finance"
                        title="Care Finance" />

                    <h1 (click)="scrollToSection()" class="title">FAQ</h1>
                </div> -->
                <div class="main-container">
                    <div class="text-content">
                        <h2 class="title-white">Collection Partners</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="section-2">
        <div class="container">
            <ol>
                <li>
                    <p>

                        Medikash
                    </p>
                </li>
                <li>
                    <p>
                        Western Fintrade
                    </p>

                </li>


            </ol>
        </div>
    </div>
</div>