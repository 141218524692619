import {Component} from '@angular/core';
import { getWindow } from 'ssr-window';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: [ './footer.component.scss' ]
})
export class FooterComponent {
    currentYear: string = new Date().getFullYear().toString();
    window = getWindow();
    constructor() { }
    redirectToUrl(url: string, event: Event | undefined = undefined) {
        this.window.open(url, '_blank');

        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    redirectToInternalUrl(url: string) {
        this.window.open(url, '_blank');
    }

    scrollUp() {
        console.log('Scroll');

        this.window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }
}
