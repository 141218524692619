import {
    Component,
    HostListener,
    Inject,
    OnInit,
    PLATFORM_ID
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    getDocument, getWindow 
} from 'ssr-window';
import { Router } from '@angular/router';

@Component({
    selector: 'app-policy',
    templateUrl: './policy.component.html',
    styleUrl: './policy.component.scss'
})
export class PolicyComponent implements OnInit {
    platformId: object = {};
    loading: boolean = true;

    width: any;
    window: Window;
    stats: any = {
        tvl_usd: 0
    };

    category_id: string = '';
    pools: Array<any> = [];
    fixed_income_pools: Array<any> = [];
    private_equity_pools: Array<any> = [];
    device: string = 'web';
    document = getDocument();

    transactionAnimationInterval: any;
    transactions: Array<any> = [];

    marquee_items: Array<{
        image_url: string;
    }> = [];
    marquee_per_cycle_duration = 2;
    marquee_duplication = 30;

    numberFlippingAnimation: any = {
        steps: 15,
        interval: 100,
        clearIntervals: {}
    };

    openedFaqIndex: string = '-1';
    faqs: Array<{
        question: string;
        answer: string;
        index: string;
    }> = [];

    currentSlide: number = 0;

    slides = [
        {
            image: 'https://assets.carefinance.xyz/Mobilesection1.png',
            text: 'Download the CareFinance app and create your account in minutes'
        },
        {
            image: 'https://assets.carefinance.xyz/Mobilesection2.png',
            text: 'Choose the medical service you need and apply for financing'
        },
        {
            image: 'https://assets.carefinance.xyz/Mobilesection3.png',
            text: 'Receive your loan directly into hospital bank account and repay at zero interest within 90 days'
        }
    ];

    constructor(
        private cfAlertService: CfAlertService,
        @Inject(PLATFORM_ID) platformId: object,
        private router: Router
    ) {
        this.platformId = platformId;
        this.window = getWindow();
    }

    @HostListener('window:resize', [ '$event' ])
    ngOnInit(): void {
        this.width = this.window.outerWidth;

        this.getFaqs();

        if (this.width < 768) 
            this.device = 'mobile';
    }

    prevSlide() {
        if (this.currentSlide > 0) 
            this.currentSlide--;
    }

    nextSlide() {
        if (this.currentSlide < this.slides.length - 1) 
            this.currentSlide++;
    }

    getFaqs() {
        this.faqs = [
            {
                index: '0',
                question: 'What is Carefinance?',
                answer: 'CareFinance provides a Buy Now, Pay Later (BNPL) solution for healthcare, allowing patients to access medical services without an immediate financial burden. The borrowed amount can be repaid at zero interest within a 90-day period.'
            },
            {
                index: '1',
                question: 'How do I get the latest version of the app?',
                answer: 'You can download or update the CareFinance app from the Google Play Store or Apple App Store. Always ensure you are using the latest version for the best experience.'
            },
            {
                index: '2',
                question: 'What are other customers saying about Carefinance?',
                answer: 'Customers appreciate CareFinance for its seamless, interest-free healthcare financing solutions that help them access medical services without upfront payment pressures.'
            },
            {
                index: '3',
                question: 'What is required to receive a Carefinance loan?',
                answer: 'To receive a CareFinance loan, you need to download the app, register using your Aadhar-linked mobile number, and submit necessary details, including credit history and income information. A credit limit will then be assigned based on your profile.'
            },
            {
                index: '4',
                question: 'How does Carefinance make a lending decision?',
                answer: 'CareFinance evaluates your credit history, income, and other factors to assign a credit limit. This is used to determine the loan amount you’re eligible for.'
            },
            {
                index: '5',
                question: 'How long does the application process take?',
                answer: 'The registration and loan approval process is quick. Once you submit your hospital bill through the app, CareFinance processes and approves the loan within a few hours.'
            },
            {
                index: '6',
                question: 'Why was I rejected for a loan?',
                answer: 'Loan rejections could occur due to insufficient credit history, low income, or other factors identified during the credit assessment.'
            },
            {
                index: '7',
                question: 'What if someone else applied with my phone?',
                answer: 'Only authorized individuals should apply for loans. If you suspect fraudulent activity, contact CareFinance support immediately to block any unauthorized transactions.'
            },
            {
                index: '8',
                question: 'How do I apply for a loan?',
                answer: 'Simply download the CareFinance app, register, and follow the on-screen instructions to submit your hospital bill and request a loan.'
            },
            {
                index: '9',
                question: 'How much can I borrow?',
                answer: 'Your borrowing limit depends on your credit profile, income, and credit history. Once your limit is assigned, you can borrow up to the available credit.'
            },
            {
                index: '10',
                question: 'How do you determine my loan offers?',
                answer: 'Your loan offers are determined by your credit score, income, and repayment history. The credit limit defines the maximum amount you can borrow.'
            },
            {
                index: '11',
                question: 'Can I apply for a higher amount than shown?',
                answer: 'No, the loan amount is determined by your assigned credit limit, but it may increase over time as you build a positive repayment history.'
            },
            {
                index: '12',
                question: 'What\'s the largest amount I can take?',
                answer: 'Your maximum loan amount is based on the credit limit assigned to you during the registration process.'
            },
            {
                index: '13',
                question: 'What interest rate does Carefinance charge?',
                answer: 'There’s no interest on loans during the BNPL period. However, if you convert the loan into EMIs after the BNPL period, interest rates range from 18% to 36% depending on your credit score.'
            },
            {
                index: '14',
                question: 'Will my loan size increase every time I repay?',
                answer: 'Yes, successfully repaying your loans on time helps increase your credit limit, allowing you to borrow more in the future.'
            },
            {
                index: '15',
                question: 'How fast will my loan size increase?',
                answer: 'Your loan size increases incrementally with each successful repayment. The exact growth depends on your credit profile and repayment history.'
            },
            {
                index: '16',
                question: 'How should I repay?',
                answer: 'You can repay your loan through the CareFinance app using various payment methods such as debit cards, UPI, or net banking.'
            },
            {
                index: '17',
                question: 'Where can I see my repayment schedule?',
                answer: 'Your repayment schedule is available in the CareFinance app under the loan details section.'
            },
            {
                index: '18',
                question: 'My repayment didn\'t reflect, what should I do?',
                answer: 'If your repayment isn’t showing up, check with your bank and contact CareFinance customer support through the app for assistance.'
            },
            {
                index: '19',
                question: 'Can I repay early?',
                answer: 'Yes, CareFinance allows early repayments without penalties. Early repayments may help increase your credit limit for future loans.'
            },
            {
                index: '20',
                question: 'What will happen if I miss my repayment?',
                answer: 'Missed repayments can lead to penalties, increased interest rates, and a negative impact on your credit score.'
            },
            {
                index: '21',
                question: 'What is CIBIL?',
                answer: 'CIBIL is India’s leading credit information bureau that tracks your credit history and score, which lenders use to assess your creditworthiness.'
            },
            {
                index: '22',
                question: 'How do I validate my contact number?',
                answer: 'During registration, you will receive an OTP on your mobile number. Enter this OTP in the app to validate your number.'
            },
            {
                index: '23',
                question: 'What if I don\'t know my PAN number?',
                answer: 'Your PAN number is a mandatory requirement for availing of financial services. You can retrieve it through the Income Tax Department’s website if you’ve forgotten it.'
            },
            {
                index: '24',
                question: 'Can I delete my account?',
                answer: 'Yes, you can request to delete your account through the CareFinance app or by contacting customer support.'
            },
            {
                index: '25',
                question: 'Can I trust Carefinance with my personal details?',
                answer: 'Yes, CareFinance employs state-of-the-art encryption methods and follows strict data privacy standards to protect your personal information.'
            },
            {
                index: '26',
                question: 'Why does Carefinance need my PAN?',
                answer: 'CareFinance requires your PAN for identity verification and to comply with legal and regulatory requirements.'
            },
            {
                index: '27',
                question:
                    'Where can I find Terms of use and relevant policies?',
                answer: 'You can find CareFinance’s Terms of Use and other policies within the app under the profile section.'
            },
            {
                index: '28',
                question:
                    'Where can I find information on Carefinance Grievance redressal policy and fair practices code?',
                answer: 'Information regarding CareFinance’s Grievance Redressal Policy and Fair Practices Code can be found on our website.'
            }
        ];
    }

    openFaq(index: string) {
        if (this.openedFaqIndex === index) this.openedFaqIndex = '-1';
        else this.openedFaqIndex = index;
    }

    redirectToUrl(url: string, event: Event | undefined = undefined) {
        this.window.open(url, '_blank');

        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    redirectToInternalUrl(url: string) {
        this.window.open(url, '_blank');
    }

    scrollUp() {
        console.log('Scroll');

        this.window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    scrollDown() {
        console.log('Scroll');

        window.scroll({
            top: document.body.scrollHeight, // Scrolls to the bottom
            behavior: 'smooth' // Smooth scrolling
        });
    }

    scrollToSection() {
        const target = document.getElementById('target-section');

        if (target) {
            target.scrollIntoView({
                behavior: 'smooth' 
            });
        }
    }
}
