<div class="app-face-code-conduct-policy">

    <div class="content">
        <!-- <div class="left-section">
            <div class="logo">
                <img src="https://assets.carefinance.xyz/Carewhitelogo.png" alt="Care Finance" title="Care Finance"
                    loading="lazy" />
            </div>
        </div> -->

        <div class="privacy-content">

            <h1 class="heading">Face Code of Conduct Policy</h1>
            <br>
            <br>
            <div class="paragraph">





                <ol>
                    <h4>Code of Conduct for Digital Lending</h4>
                    <!-- <p class="bold"> Code of Conduct for Digital Lending -->
                    <!-- </p> -->



                    <li>
                        <p class="bold">Introduction</p>
                        <p>This Code of Conduct ("Code") for CareFinance Healthcare App outlines the ethical standards,
                            responsibilities,
                            and expectations for digital lending services provided to our customers. The Code ensures
                            fair, transparent, and
                            responsible lending practices, emphasizing the importance of customer protection, data
                            privacy, and regulatory
                            compliance.</p>
                        <p>This Code applies to all stakeholders involved in digital lending through the CareFinance
                            platform, including
                            lenders, borrowers, employees, agents, and third-party service providers.</p>
                    </li>

                    <li>
                        <p class="bold">Core Principles</p>
                        <ol>
                            <ul>
                                <li>
                                    <p><strong>Fairness:</strong> All lending services must be conducted with fairness
                                        and
                                        transparency to
                                        ensure that borrowers fully understand the terms and conditions of the loans.
                                    </p>
                                </li>
                                <li>
                                    <p><strong>Accountability:</strong> CareFinance and its stakeholders are accountable
                                        for
                                        ensuring
                                        responsible lending practices that meet regulatory and ethical standards.</p>
                                </li>
                                <li>
                                    <p><strong>Confidentiality:</strong> Borrowers' personal and financial data must be
                                        treated with the highest
                                        level of confidentiality and protected from unauthorized access.</p>
                                </li>
                                <li>
                                    <p><strong>Compliance:</strong> All lending activities must comply with applicable
                                        laws,
                                        regulations, and
                                        guidelines, including those related to consumer protection, financial services,
                                        and
                                        data security.</p>
                                </li>
                                <li>
                                    <p><strong>Empathy and Accessibility:</strong> CareFinance is committed to providing
                                        accessible financial
                                        products for healthcare needs, with a customer-centric approach that emphasizes
                                        empathy and
                                        understanding.</p>
                                </li>
                            </ul>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Responsibilities of the Lender</p>
                        <ol>
                            <li class="hide">
                                <p><strong>3.1. Transparency in Loan Terms</strong></p>
                                <p>Lenders must ensure that all loan terms, including interest rates, fees, penalties,
                                    repayment schedules,
                                    and total cost of the loan, are clearly communicated to borrowers in a simple and
                                    understandable manner.
                                    Lenders must not mislead borrowers by hiding or omitting critical information that
                                    may affect their
                                    decision-making.</p>
                            </li>
                            <li class="hide">
                                <p><strong>3.2. Fair Assessment of Borrowers</strong></p>
                                <p>Lenders must use ethical practices when assessing a borrower's eligibility for a
                                    loan. Any use of
                                    algorithms or automated decision-making tools must be unbiased and based on
                                    accurate, up-to-date
                                    information. Lenders must conduct a responsible lending assessment to ensure that
                                    the loan offered is
                                    suitable for the borrower’s financial situation and healthcare needs.</p>
                            </li>
                            <li class="hide">
                                <p><strong>3.3. Preventing Over-Indebtedness</strong></p>
                                <p>Lenders must not encourage or approve loans that would result in over-indebtedness
                                    for borrowers. Loans
                                    should only be granted when the borrower can reasonably afford the repayments
                                    without undue hardship. A
                                    detailed risk assessment must be conducted to ensure that the loan aligns with the
                                    borrower’s ability to
                                    repay.</p>
                            </li>
                            <li class="hide">
                                <p><strong>3.4. Collection Practices</strong></p>
                                <p>Lenders must adopt fair and ethical collection practices. Collection efforts must be
                                    respectful, without
                                    harassment, threats, or intimidation. Lenders must give borrowers adequate time and
                                    notice before taking
                                    any legal action or reporting them to credit agencies.</p>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Responsibilities of the Borrower</p>
                        <ol>
                            <li class="hide">
                                <p><strong>4.1. Accurate Disclosure of Information</strong></p>
                                <p>Borrowers must provide accurate, truthful, and complete information when applying for
                                    a loan, including
                                    their personal and financial details. Any changes in a borrower’s financial
                                    situation that may affect
                                    their ability to repay the loan must be communicated promptly to CareFinance.</p>
                            </li>
                            <li class="hide">
                                <p><strong>4.2. Responsible Borrowing</strong></p>
                                <p>Borrowers are responsible for understanding the terms of the loan and ensuring that
                                    they only borrow
                                    amounts that they can repay according to the agreed schedule. Borrowers should not
                                    take loans that
                                    exceed their healthcare needs or financial capacity.</p>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Data Privacy and Security</p>
                        <ol>
                            <li class="hide">
                                <p><strong>5.1. Protection of Personal Data</strong></p>
                                <p>CareFinance is committed to protecting the privacy of borrowers and ensuring that
                                    their personal and
                                    financial data is secure. All data collection, processing, and storage must comply
                                    with applicable data
                                    protection laws, such as the General Data Protection Regulation (GDPR) or relevant
                                    local regulations.
                                    Personal data must only be accessed by authorized personnel and used for legitimate
                                    purposes related to
                                    the loan application, management, and repayment processes.</p>
                            </li>
                            <li class="hide">
                                <p><strong>5.2. Secure Digital Transactions</strong></p>
                                <p>All digital lending transactions on the CareFinance platform must be encrypted and
                                    conducted through
                                    secure channels to prevent fraud or unauthorized access.</p>
                            </li>
                            <li class="hide">
                                <p><strong>5.3. Informed Consent</strong></p>
                                <p>Borrowers must provide informed consent before any personal data is collected,
                                    shared, or processed.
                                    Lenders must explain how borrower data will be used and give borrowers the option to
                                    opt-out where
                                    possible.</p>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Interest Rates, Fees, and Charges</p>
                        <ol>
                            <li class="hide">
                                <p><strong>6.1. Reasonable Interest Rates</strong></p>
                                <p>Lenders must ensure that the interest rates on loans are fair and in compliance with
                                    legal regulations.
                                    The interest rates should reflect the borrower’s risk profile, market conditions,
                                    and healthcare needs
                                    without exploiting vulnerable borrowers.</p>
                            </li>
                            <li class="hide">
                                <p><strong>6.2. No Hidden Charges</strong></p>
                                <p>All fees and charges related to the loan, including processing fees, late payment
                                    penalties, and early
                                    repayment charges, must be clearly disclosed before the borrower agrees to the loan.
                                </p>
                            </li>
                            <li class="hide">
                                <p><strong>6.3. Timely Communication</strong></p>
                                <p>Lenders must notify borrowers promptly of any changes in interest rates, fees, or
                                    repayment schedules.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Default and Recovery Mechanism</p>
                        <ol>
                            <li class="hide">
                                <p><strong>7.1. Grace Periods and Loan Restructuring</strong></p>
                                <p>In cases where borrowers are unable to repay the loan on time due to unforeseen
                                    circumstances, lenders
                                    must offer reasonable grace periods or the possibility of restructuring the loan to
                                    make it more
                                    manageable. Borrowers facing financial hardship must be offered the opportunity to
                                    renegotiate repayment
                                    terms in a fair and empathetic manner.</p>
                            </li>
                            <li class="hide">
                                <p><strong>7.2. Non-Coercive Recovery Practices</strong></p>
                                <p>Lenders must avoid coercive tactics when recovering unpaid loans. Communication with
                                    borrowers in default
                                    must remain professional and respectful at all times.</p>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Customer Support and Grievance Redressal</p>
                        <ol>
                            <li class="hide">
                                <p><strong>8.1. Accessible Customer Support</strong></p>
                                <p>CareFinance must provide accessible customer support services to address any borrower
                                    inquiries,
                                    disputes, or issues regarding loan applications, repayments, or other services.
                                    Borrowers should have
                                    multiple channels (e.g., phone, email, chat) to contact the support team.</p>
                            </li>
                            <li class="hide">
                                <p><strong>8.2. Grievance Redressal Mechanism</strong></p>
                                <p>A transparent and efficient grievance redressal process must be in place to handle
                                    borrower complaints.
                                    All complaints must be acknowledged and resolved within a reasonable time frame,
                                    with borrowers informed
                                    of the progress.</p>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Regulatory Compliance</p>
                        <ol>
                            <li class="hide">
                                <p><strong>9.1. Adherence to Laws and Regulations</strong></p>
                                <p>All digital lending practices must comply with the applicable laws, including those
                                    related to consumer
                                    protection, lending, and financial services. This includes compliance with central
                                    banking guidelines,
                                    financial regulatory bodies, and any specific laws governing digital lending in the
                                    healthcare sector.
                                </p>
                            </li>
                            <li class="hide">
                                <p><strong>9.2. Reporting and Documentation</strong></p>
                                <p>Lenders must maintain accurate records of all lending transactions, including loan
                                    applications,
                                    approvals, repayments, and borrower communications. These records must be available
                                    for regulatory
                                    audits and inspections.</p>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Ethical Considerations</p>
                        <ol>
                            <li class="hide">
                                <p><strong>10.1. Patient-Centric Lending</strong></p>
                                <p>The primary purpose of digital lending through CareFinance is to assist patients in
                                    accessing healthcare
                                    services. Therefore, lending practices must prioritize the well-being of the
                                    borrower, ensuring loans
                                    are structured to meet healthcare needs without causing financial stress.</p>
                            </li>
                            <li class="hide">
                                <p><strong>10.2. Continuous Education</strong></p>
                                <p>Lenders must provide borrowers with access to educational resources on responsible
                                    borrowing, financial
                                    management, and the implications of defaulting on loans.</p>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Amendments and Updates</p>
                        <p>CareFinance reserves the right to update or amend this Code of Conduct periodically to
                            reflect changes in
                            regulatory requirements, business practices, or market conditions. All stakeholders will be
                            informed of such
                            changes, and it is their responsibility to comply with the most recent version of the Code.
                        </p>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Conclusion</p>
                        <p>The Code of Conduct for Digital Lending ensures that CareFinance operates with the highest
                            standards of fairness,
                            transparency, and customer protection. By adhering to this Code, CareFinance aims to foster
                            a responsible
                            lending environment that meets the healthcare financing needs of our borrowers while
                            maintaining trust and
                            ethical integrity.</p>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Contact Information for Reporting Violations or Grievances</p>
                        <p>CareFinance Healthcare App</p>
                        <p>Customer Support Department</p>
                        <p>Email Address: shrikant&#64;cipherventurecapital.com</p>
                    </li>







                </ol>
            </div>
        </div>
    </div>

</div>