import {
    Component, OnInit 
} from '@angular/core';

@Component({
    selector: 'app-fair-practice-code-policy',
    templateUrl: './fair-practice-code-policy.component.html',
    styleUrls: [ './fair-practice-code-policy.component.scss' ]
})
export class FairPracticeCodePolicyComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }
}
