import {
    RouterModule, Routes 
} from '@angular/router';
import { NgModule } from '@angular/core';
import { TermsConditionComponent } from './policies/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './policies/privacy-policy/privacy-policy.component';
import { HomepageComponent } from './homepage/homepage.component';
import { PolicyComponent } from './policies/policy.component';
import { InterestRatePolicyComponent } from './policies/Interest-rate-policy/Interest-rate-policy.component';
import { FaceCodeConductPolicyComponent } from './policies/face-code-conduct-policy/face-code-conduct-policy.component';
import { GrievanceRedressalMechanismPolicyComponent } from './policies/grievance-redressal-mechanism-policy/grievance-redressal-mechanism-policy.component';
import { FairPracticeCodePolicyComponent } from './policies/fair-practice-code-policy/fair-practice-code-policy.component';
import { FAQComponent } from './FAQ/faq.component';
import { CollectionPartnersComponent } from './collection-partners/collection-partners.component';
import { DLAICodeConductPolicyComponent } from './policies/dlai-code-conduct-policy/dlai-code-conduct-policy.component';
import { OurPartnersComponent } from './our-partners/our-partners.component';
import { GrievanceRedressalComponent } from './grievance-redressal/grievance-redressal.component';
// import { AirdropComponent } from './airdrop/airdrop.component';

export const routes: Routes = [
    {
        path: '',
        component: HomepageComponent,
        data: {
            title: '',
            description: 'Early invest in private credit and equities',
            showSidebar: false,
            showHeader: true
        }
    },
    {
        path: 'policies',
        component: PolicyComponent,
        data: {
            title: 'Policies',
            description: 'Policies',
            showSidebar: false,
            showHeader: true
        }
    },
    {
                
        path: 'policies/interest-rate-policy',
        component: InterestRatePolicyComponent,
        data: {
            title: 'Interests Rate Policy',
            description: 'Interests Rate Policy',
            showSidebar: false,
            showHeader: true,
            showConnectWallet: false
        }
    },
    {
                
        path: 'policies/terms-condition',
        component: TermsConditionComponent,
        data: {
            title: 'Terms and conditions',
            description: 'Terms and Conditions',
            showSidebar: false,
            showConnectWallet: false
        }
    },
    {
        path: 'policies/privacy-policy',
        component: PrivacyPolicyComponent,
        data: {
            title: 'Privacy Policy',
            description: 'Privacy Policy',
            showSidebar: false,
            showConnectWallet: false
        }
    },
    {
        path: 'policies/face-code-of-conduct-policy',
        component: FaceCodeConductPolicyComponent,
        data: {
            title: 'FACE Code of Conduct Policy',
            description: 'FACE Code of Conduct Policy',
            showSidebar: false,
            showConnectWallet: false
        }
    },
    {
        path: 'policies/dlai-code-of-conduct-policy',
        component: DLAICodeConductPolicyComponent,
        data: {
            title: 'DLAI Code of Conduct Policy',
            description: 'DLAI Code of Conduct Policy',
            showSidebar: false,
            showConnectWallet: false
        }
    },
    {
        path: 'policies/grievance-redressal-policy',
        component: GrievanceRedressalMechanismPolicyComponent,
        data: {
            title: 'Grievance Redressal Policy',
            description: 'Grievance Redressal Policy',
            showSidebar: false,
            showConnectWallet: false
        }
    },
    {
        path: 'policies/fair-practice-code-policy',
        component: FairPracticeCodePolicyComponent,
        data: {
            title: 'Fair Practice Code Policy',
            description: 'Fair Practice Code Policy',
            showSidebar: false,
            showConnectWallet: false
        }
    },
    
    {
        path: 'partners',
        component: OurPartnersComponent,
        data: {
            title: 'Our Partners',
            description: 'Our Partners',
            showSidebar: false,
            showConnectWallet: false
        }
    },
    {
        path: 'grievance-redressal',
        component: GrievanceRedressalComponent,
        data: {
            title: 'Grievance Redressal',
            description: 'Grievance Redressal',
            showSidebar: false,
            showConnectWallet: false
        }
    },
    {
        path: 'collection-partners',
        component: CollectionPartnersComponent,
        data: {
            title: 'Collection Partners',
            description: 'Collection Partners',
            showSidebar: false,
            showConnectWallet: false
        }
    },
    
    {
        path: 'faq',
        component: FAQComponent,
        data: {
            title: 'FAQ',
            description: 'Frequently asked questions',
            showSidebar: false,
            showConnectWallet: false
        }
    }

    // {
    //     path: '**',
    //     component: NotFoundComponent,
    //     data: {
    //         title: 'Not found',
    //         description: 'Not found',
    //     },
    // },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledNonBlocking'
        })
    ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}
