// console.log('app.module.ts start');

import {
    LOCALE_ID, NgModule 
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import {
    FormsModule, ReactiveFormsModule 
} from '@angular/forms';
import {
    MatDialogModule, MatDialogRef 
} from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
    CfAlertModule,
    CfCardModule,
    CfCheckboxModule,
    CfChipModule,
    CfDropdownModule,
    CfInputCurrencyDropdownModule,
    CfInputModule,
    CfToggleModule,
    CfLoaderModule,
    CfMobileNumberModule,
    CfButtonModule,
    CfPaginatorModule,
    CfInputCurrencyModule,
    CfTabsModule,
    CfOtpModule,
    CfCircularProgressBarModule
} from '@crediblefinance/credible-ui';
import {
    DatePipe, DecimalPipe 
} from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi
} from '@angular/common/http';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AddHeaderInterceptor } from '../interceptors/add-header.interceptor';
import { HomepageComponent } from './homepage/homepage.component';
import { TermsConditionComponent } from './policies/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './policies/privacy-policy/privacy-policy.component';
import { PolicyComponent } from './policies/policy.component';
import { InterestRatePolicyComponent } from './policies/Interest-rate-policy/Interest-rate-policy.component';
import { GrievanceRedressalMechanismPolicyComponent } from './policies/grievance-redressal-mechanism-policy/grievance-redressal-mechanism-policy.component';
import { FairPracticeCodePolicyComponent } from './policies/fair-practice-code-policy/fair-practice-code-policy.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FAQComponent } from './FAQ/faq.component';
import { CollectionPartnersComponent } from './collection-partners/collection-partners.component';
import { DLAICodeConductPolicyComponent } from './policies/dlai-code-conduct-policy/dlai-code-conduct-policy.component';
import { OurPartnersComponent } from './our-partners/our-partners.component';
import { GrievanceRedressalComponent } from './grievance-redressal/grievance-redressal.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        MatDialogModule,
        MatRadioModule,
        MatStepperModule,
        CfAlertModule,
        CfCardModule,
        CfCheckboxModule,
        CfChipModule,
        CfDropdownModule,
        CfInputCurrencyDropdownModule,
        CfInputModule,
        CfToggleModule,
        CfTabsModule,
        CfLoaderModule,
        CfMobileNumberModule,
        CfButtonModule,
        CfPaginatorModule,
        CfInputCurrencyModule,
        CfCircularProgressBarModule,
        AppRoutingModule,
        CfOtpModule,
        MatExpansionModule
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        HomepageComponent,
        PolicyComponent,
        DLAICodeConductPolicyComponent,
        OurPartnersComponent,
        GrievanceRedressalComponent,
        CollectionPartnersComponent,
        InterestRatePolicyComponent,
        FairPracticeCodePolicyComponent,
        GrievanceRedressalMechanismPolicyComponent,
        TermsConditionComponent,
        PrivacyPolicyComponent,
        TermsConditionComponent,
        PrivacyPolicyComponent,
        FAQComponent,
        FooterComponent
    ],
    exports: [],
    providers: [
        DecimalPipe,
        DatePipe,
        {
            provide: MatDialogRef,
            useValue: {}
        },
        {
            provide: LOCALE_ID,
            useValue: 'en-IN'
        },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {
                displayDefaultIndicatorType: false
            }
        },
        // provideClientHydration(),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddHeaderInterceptor,
            multi: true
        }
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule {}
