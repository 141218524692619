<div class="app-footer">
    <div class="main-container">
        <div class="top-container">
            <div class="document-container">
                <img class="profile-img" src="https://assets.carefinance.xyz/Carewhitelogo.png" alt="Care Finance"
                    title="Care Finance" />
            </div>
            <div class="document-container">
                <!-- <h1 class="title">Company</h1> -->
                <!-- <h1 class="sub-title" routerLink="/liquidity">About Us</h1> -->
                <!-- <h1 class="sub-title" routerLink="/liquidity">Work With Us</h1> -->
                <!-- <h1 class="sub-title" routerLink="/liquidity">Blog & News</h1> -->
            </div>
            <div class="document-container">
                <h1 class="title">Legal</h1>
                <a routerLink="/policies" class="sub-title">Policies</a>

                <!-- <h1 class="sub-title" routerLink="/liquidity">Corporate Information</h1> -->
                <h1 class="sub-title" routerLink="/grievance-redressal">Grievance Redressal</h1>
                <h1 class="sub-title" routerLink="/partners">Our Partners</h1>
                <h1 class="sub-title" routerLink="/collection-partners">Collection Partners</h1>
                <!-- <a class="sub-title" href="https://twitter.com/crediblefin" target="_blank">Twitter</a>
                    <a class="sub-title" href="https://t.me/crediblefinance" target="_blank">Telegram</a>
                    <a class="sub-title" href="https://medium.com/@credible_finance" target="_blank">Medium</a> -->
            </div>
            <div class="document-container">
                <h1 class="title">Download</h1>
                <img class="store-img" src="https://assets.carefinance.xyz/playstore.png" alt="Care Finance"
                    title="Care Finance" />
                <img class="store-img" src="https://assets.carefinance.xyz/appstore.png" alt="Care Finance"
                    title="Care Finance" />
                <!-- <a href="https://credible.gitbook.io/" target="_blank" class="sub-title">Whitepaper</a>
                    <a href="https://docsend.com/view/c4nm6fpy6kfk2vez" target="_blank" class="sub-title">Litepaper</a> -->
                <!-- <h1 class="sub-title">Developer Docs</h1> -->
            </div>
            <!-- <div class="document-container">
                    <h1 class="title">Legal</h1>
                    <a (click)="redirectToUrl('/privacy-policy')" target="_blank" class="sub-title">Privacy
                        Policy</a>
                    <a (click)="redirectToUrl('/terms-condition')" target="_blank" class="sub-title">Terms
                        And Conditions</a>
                </div> -->
            <!-- <div class="social-container">
                    <div class="social-card">
                        <div class="card-header">
                            <div class="card-profile-section">
                                <img class="profile-img" src="https://assets.carefinance.xyz/Appicon1.png"
                                    alt="Care Finance" title="Care Finance" />
                                <div class="profile-name">
                                    <span class="social-card-title">Carefinance</span>
                                    <span class="social-card-sub-title">&#64;Care</span>
                                </div>
                            </div>
                            <a href="https://twitter.com/crediblefin" target="_blank">
                                <i class="fa-brands fa-twitter"></i>
                            </a>
                        </div>
                        <p class="card-content">We’re just announced new feature that would help you increase your
                            experience of using CareFinance!
                        </p>
                    </div>
                </div> -->
        </div>

        <div class="bottom-container">
            <hr class="divider">
            <div class="social-links">
                <div class="logo-name">
                    <!-- <img class="profile-img" src="https://assets.carefinance.xyz/Appicon1.png" alt="Care Finance"
                            title="Care Finance" /> -->
                    <span class="social-card-title">Copyright © 2024 Carefinance. All rights reserved.</span>
                </div>
                <!-- <div class="social-media-link">
                        <img class="social-media-img" src="https://assets.carefinance.xyz/Twitter1.png"
                            alt="Care Finance" title="Care Finance" />
                    </div> -->
            </div>
        </div>

    </div>
</div>