<div class="app-header">
    <div class="section-1">
        <div class="background">
            <div class="container">
                <div class="header-container">
                    <img routerLink="/" class="care-logo" src="https://assets.carefinance.xyz/Carewhitelogo.png"
                        alt="Care Finance" title="Care Finance" />

                    <h1 routerLink="/faq" (click)="scrollToSection()" class="title">FAQ</h1>
                </div>

            </div>
        </div>

    </div>
</div>