<div class="privacy-policy-container">

    <div class="content">
        <!-- <div class="left-section">
            <div class="logo">
                <img src="https://assets.carefinance.xyz/Carewhitelogo.png" alt="Care Finance" title="Care Finance"
                    loading="lazy" />
            </div>
        </div> -->

        <div class="privacy-content">

            <h1 class="heading">Privacy Policy</h1>
            <br>
            <br>
            <div class="paragraph">



                <ol>
                    <li class="hide">
                        <p class="bold">
                            Introduction

                        </p>
                        <p>
                            Welcome to Cipherhut software India Pvt Ltd DBA <strong>Carefinance</strong>, a healthcare
                            loan platform dedicated to providing financial
                            assistance
                            for medical treatments.
                            Our Privacy Policy outlines how we collect, use, process, and safeguard the personal
                            information
                            you share with us when
                            using our mobile application (the “App”) and website (the “Website”). By accessing or using
                            our
                            services (“Services”)
                            through the App or Website, you acknowledge and agree to this Privacy Policy, as well as our
                            Terms and Conditions.


                        </p>
                        <p>This Privacy Policy addresses:</p>
                        <ol>

                            <li>
                                The types of information we collect from users, including personal and financial data.

                            </li>
                            <li>
                                The methods of collecting, processing, and securing your information.

                            </li>
                            <li>
                                How we disclose your information to third parties.

                            </li>


                        </ol>
                        <p>We respect your privacy and are committed to protecting your personal data. Our Privacy
                            Policy
                            outlines our practices in
                            compliance with applicable data protection laws and regulations.
                        </p>
                    </li>

                    <br />


                    <li class="hide">
                        <p class="bold">
                            Consent
                        </p>
                        <p>
                            By using our App or Website, you consent to the collection, use, storage, and disclosure of
                            your
                            information as outlined in this Privacy Policy. The personal information you provide will be
                            used
                            solely to offer and improve our Services.
                        </p>
                        <p class="bold">
                            What Information We Collect
                        </p>
                        <p>
                            We collect the following types of information from you during your use of our App or
                            Website:
                        </p>
                        <ol>
                            <li>
                                <strong>Personal Information:</strong> This may include your name, address, date of
                                birth,
                                identification details, email address, and phone number.
                            </li>
                            <li>
                                <strong>Financial Information:</strong> To provide our healthcare loan services, we
                                collect your
                                credit history, bank account information, transaction details, and other necessary
                                financial
                                data. This also includes information obtained from your credit reports and loan
                                repayment details.
                            </li>
                            <li>
                                <strong>Device Information:</strong> This includes IP addresses, device identifiers,
                                operating
                                systems, browser types, and other technical details regarding your use of the App.
                            </li>
                            <li>
                                <strong>Usage Data:</strong> Information such as the pages visited, content viewed, and
                                interactions with our Services may also be collected for analytical purposes.
                            </li>
                        </ol>
                        <p>
                            When you apply for a loan, we collect and process data necessary for determining
                            eligibility,
                            including verifying your creditworthiness, identity, and other financial assessments.
                        </p>
                    </li>
                    <br />
                    <li class="hide">
                        <p class="bold">
                            Why We Collect Information
                        </p>
                        <p>
                            We collect your personal and financial information for the following purposes:
                        </p>
                        <ol>
                            <li>
                                <strong>Contractual Purposes:</strong>
                                <ul>
                                    <li>To assess your eligibility for loans and facilitate loan disbursement.</li>
                                    <li>To communicate with you about loan approvals, repayment schedules, and other
                                        related Services.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Legal Obligations:</strong>
                                <ul>
                                    <li>Compliance with applicable regulations and reporting obligations, such as Know
                                        Your Customer (KYC)
                                        requirements.</li>
                                    <li>Ensuring adherence to anti-money laundering (AML) regulations and other lawful
                                        compliance checks.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Legitimate Interests:</strong>
                                <ul>
                                    <li>Fraud detection and prevention.</li>
                                    <li>Improving our Services to provide a better user experience.</li>
                                    <li>Conducting internal research and analysis to enhance our offerings.</li>
                                </ul>
                            </li>
                        </ol>
                        <br />
                        <p class="bold">
                            How We Use Your Information
                        </p>
                        <p>
                            The information we collect is used for various purposes, including but not limited to:
                        </p>
                        <ul>
                            <li>Processing loan applications and repayments.</li>
                            <li>Verifying your identity and credit score to determine loan eligibility.</li>
                            <li>Facilitating the disbursement of loan amounts to the hospital where your treatment will
                                take place.</li>
                            <li>Sending notifications regarding loan status, repayments, and updates related to your
                                account.</li>
                            <li>Improving our platform, ensuring a smooth user experience, and offering tailored
                                services.</li>
                        </ul>
                        <br />
                        <p class="bold">
                            Sharing of Information
                        </p>
                        <p>
                            We may share your personal information in the following circumstances:
                        </p>
                        <ol>
                            <li>
                                <strong>With Partner Institutions:</strong> Your information, including financial data,
                                may be shared with
                                partner lending institutions for the purpose of assessing your loan application and
                                determining your
                                creditworthiness.
                            </li>
                            <li>
                                <strong>With Hospitals:</strong> We may share relevant loan disbursement information
                                with the healthcare
                                provider or hospital where the treatment is being conducted, to ensure that the loan
                                covers your treatment
                                costs.
                            </li>
                            <li>
                                <strong>With Regulators or Legal Authorities:</strong> If required by law or in response
                                to legal processes,
                                we may disclose your information to comply with regulatory obligations.
                            </li>
                            <li>
                                <strong>Third-Party Service Providers:</strong> We may engage third-party service
                                providers for processing,
                                analytics, or marketing purposes, provided they adhere to applicable data protection
                                laws.
                            </li>
                        </ol>
                    </li>
                    <br />

                    <li class="hide">
                        <p class="bold">
                            Security of Your Information
                        </p>
                        <p>
                            We employ strict security measures to protect your personal data from unauthorized access,
                            use, or disclosure.
                            This includes encryption, secure data storage, and access restrictions. However, while we
                            strive to protect your
                            personal information, no security system is entirely foolproof, and we cannot guarantee
                            complete security of
                            your data.
                        </p>

                        <p class="bold">
                            Cookies
                        </p>
                        <p>
                            Our App and Website may use cookies or similar technologies to enhance your browsing
                            experience. Cookies help us
                            to understand user behavior, track preferences, and improve our Services. By continuing to
                            use our platform, you
                            consent to the use of cookies.
                        </p>

                        <p class="bold">
                            Third-Party Links
                        </p>
                        <p>
                            Our platform may contain links to third-party websites or services. We are not responsible
                            for the privacy
                            practices or content of these external sites. We encourage you to review their privacy
                            policies before sharing
                            any personal information with them.
                        </p>

                        <p class="bold">
                            Data Retention
                        </p>
                        <p>
                            We retain your personal data for as long as necessary to fulfill the purposes outlined in
                            this Privacy Policy,
                            unless a longer retention period is required by law. This includes maintaining accurate
                            records of loan
                            applications, disbursements, and repayments.
                        </p>

                        <p class="bold">
                            Changes to This Privacy Policy
                        </p>
                        <p>
                            We reserve the right to update this Privacy Policy periodically. Any changes will be posted
                            on our Website and
                            App, and the updated Privacy Policy will reflect the new effective date. We encourage you to
                            review this Policy
                            regularly to stay informed of any changes.
                        </p>

                        <p class="bold">
                            Governing Law and Dispute Resolution
                        </p>
                        <p>
                            This Privacy Policy is governed by the laws of India. Any disputes arising from or related
                            to this Privacy
                            Policy will be subject to the exclusive jurisdiction of the courts of Maharashtra, India.
                        </p>

                        <p class="bold">
                            Contact Us
                        </p>
                        <p>
                            If you have any questions or concerns regarding this Privacy Policy or how we handle your
                            personal information,
                            please contact us at shrikant&#64;cipherventurecapital.com.
                        </p>
                    </li>


                </ol>

            </div>
        </div>
    </div>

</div>