const serverUrl = 'https://lendapi.carefinance.xyz/';
const socketUrl = '';
const webUrl = 'https://carefinance.xyz';
const RECAPTCHA_SITE_KEY = '6LdxsVAqAAAAAN1aqyui3jt9TSv-lGEIZm7W6Sng';
const paynormaKycBaseUrl = 'https://kyc.paynorma.com';
const currencyConversionMapUrl = 'https://conversion.seracle.com/getCurrencyConversionMap';

const cluster = 'devnet';
const networks: any = {
    devnet: {
        name: 'Devnet',
        rpc: 'https://devnet.helius-rpc.com/?api-key=ced154f2-8235-4e5f-a14f-a47754cfee51'
    },
    'mainnet-beta': {
        name: 'Mainnet Beta',
        rpc: 'https://rpc.ironforge.network/mainnet?apiKey=01J1F4HJ98JTZA4R10Y0ZBGBSX'
    }
};
const merchant_redirect_url = 'https://credible.finance/liquidity/';
const apiKey = 'd028fb5a-d8dd-4138-b5e9-28fe4ba5dc63';
const secretKey = 'b91f7fa5-64e8-4578-b7b5-33aaec181563';
const showConsoleLogs = false;

export {
    showConsoleLogs,
    serverUrl,
    socketUrl,
    RECAPTCHA_SITE_KEY,
    paynormaKycBaseUrl,
    currencyConversionMapUrl,
    webUrl,
    cluster,
    networks,
    merchant_redirect_url,
    apiKey,
    secretKey
};
