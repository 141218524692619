<div class="app-dlai-code-conduct-policy">

    <div class="content">

        <div class="privacy-content">

            <h1 class="heading">CODE OF CONDUCT FOR RESPONSIBLE DIGITAL LENDING
            </h1>
            <br>
            <br>
            <div class="paragraph">
                <ol>

                    <li class="hide">
                        <p class="bold">I. Introduction and Implementation</p>
                        <p>
                            In furtherance of DLAI’s commitment towards creating an industry-led self-regulatory body
                            and aligning its
                            values
                            with the applicable regulatory directions, the Digital Lenders’ Association of India
                            (“DLAI”) is introducing
                            this
                            2nd edition of its code of conduct (“Code of Conduct”). The erstwhile Code of Conduct dated
                            is henceforth
                            discontinued and this Code of Conduct (2nd edition) is instituted in its place. The Code of
                            Conduct will come
                            into
                            effect from September 23, 2023. Industry self-governance, by virtue of being developed by
                            the members of the
                            industry themselves, can lead to more appropriate regulations which have higher compliance
                            rates.
                        </p>
                        <p>
                            Further, self-regulation encourages members (lending institutions) to look beyond their
                            immediate monetary
                            bottom
                            lines and internalize the larger impacts of their business – such as protecting customers,
                            furthering training
                            and
                            education for the customers and the employees alike, and striving for the development of its
                            members, the
                            industry,
                            and the ecosystem as a whole, which in turn leads to a healthier and more profitable
                            ecosystem in the long run.
                        </p>
                        <p>
                            With recent strong growth in the digital lending industry in India, there is a need for
                            industry participants to
                            maintain a strong Code of Conduct to prevent the rise of unscrupulous practices that could
                            cause harm to the
                            industry by reducing the confidence of customers, regulators, and other market participants.
                            Safeguarding the
                            customer’s interests provides the industry with trust and legitimacy, which is in the
                            digital lending industry’s
                            long-term business interests.
                        </p>
                        <p>
                            This new Code of Conduct is centered around 8 (eight) core elements. The Code of Conduct
                            sets out the processes
                            and
                            guidelines under each core element to actualise them into clear actionable points. This Code
                            of Conduct must be
                            viewed as a minimum industry standard. Thus, this Code of Conduct has been made binding on
                            every member of the
                            DLAI
                            engaged in the business of digital lending, whether regulated or not. The applicability of
                            this Code of Conduct
                            is
                            set out in Section II (Applicability) below.
                        </p>
                        <p>
                            The Reserve Bank of India (“RBI”) issued the ‘Guidelines on Digital Lending’ dated September
                            02, 2022 (“Digital
                            Lending
                            Guidelines”), pursuant to which the RBI has shifted away from the light touch approach and
                            prescribed a
                            regulatory
                            framework for digital lending in India. The Digital Lending Guidelines identify two types of
                            players in the
                            digital
                            lending ecosystem:
                        </p>
                        <ol>
                            <li>Regulated entities such as commercial banks, co-operative banks, and non-banking
                                financial companies
                                (including
                                housing finance companies) (REs).
                            </li>
                            <li>Lending service providers who enter into partnership arrangements with REs to provide
                                digital lending
                                products to
                                consumers.
                            </li>
                        </ol>
                        <p>
                            The Digital Lending Guidelines place the primary onus of compliance on the REs, as mentioned
                            below:
                        </p>
                        <ol>
                            <li>All commercial banks.</li>
                            <li>Primary (urban) co-operative banks, state co-operative banks, district central
                                co-operative banks.</li>
                            <li>Non-Banking Financial Companies (“NBFCs”) (including Housing Finance Companies).</li>
                        </ol>
                        <p>
                            The onus of compliance with the Digital Lending Guidelines is directly on the REs and not on
                            the lending service
                            providers/digital lending applications. However, the RE is required to ensure compliance by
                            lending service
                            providers/ digital lending applications, which may be achieved by way of appropriate
                            contractual arrangements
                            between REs and lending service providers/ digital lending applications.
                        </p>
                        <p>
                            The introduction of the Digital Lending Guidelines has necessitated the current revisions to
                            the Code of
                            Conduct. All
                            members of DLAI are required to incorporate the Code of Conduct as a part of their fair
                            practices code (similar
                            to
                            Banks and NBFCs). This Code of Conduct is required to be displayed by all members at every
                            point of customer
                            interface – especially on the member’s website and the digital lending application through
                            which the lending
                            activities are undertaken by the member.
                        </p>
                        <p>
                            Any concerned person may contact DLAI for any queries, information, or clarifications
                            regarding the
                            implementation of
                            the Code of Conduct at: <a href="mailto:sro@dlai.in">sro&#64;dlai.in</a>.
                        </p>
                    </li>
                    <br />

                    <li class="hide">
                        <p class="bold">II. Applicability</p>
                        <ol>
                            <li>
                                This Code of Conduct is a set of principles, processes, and guidelines that are binding
                                on every
                                RE<sup>[2]</sup>,
                                lending service provider<sup>[3]</sup>, digital lending application<sup>[4]</sup>, as
                                well as other entities
                                offering digital lending<sup>[5]</sup> products or services to customers, which are
                                affiliated with the DLAI
                                as a
                                member (“Member”).
                            </li>
                            <li>
                                For REs, lending service providers, digital lending applications, and all other Members
                                of the DLAI, this
                                Code of
                                Conduct is obligatory to follow and is a necessary condition for membership. DLAI would
                                enforce adherence to
                                the
                                Code of Conduct by Members.
                            </li>
                            <li>
                                This Code of Conduct applies to Members in all their dealings, interactions,
                                communications, transmissions,
                                and
                                transactions with any individual, person, or business (“customer”) to whom any digital
                                lending product or
                                service
                                is offered or provided by such Member.
                            </li>
                            <li>
                                Any non-adherence with the measures set out under this Code of Conduct will invite the
                                governance and
                                enforcement
                                measures set out in clause 5 (Sanctions) of Part H (Governance and Enforcement) of
                                Section IV (Code of
                                Conduct)
                                below.
                            </li>
                            <li>
                                All other REs, lending service providers, digital lending applications, and other
                                entities offering digital
                                lending products or services to customers are strongly encouraged to voluntarily adopt
                                the Code of Conduct
                                as a
                                measure of best practice by the industry.
                            </li>
                            <li>
                                This Code of Conduct aligns with and is in addition to all laws and regulations
                                applicable for lending
                                businesses,
                                including all current regulations and directions issued by the RBI, SEBI, Central and
                                State Governments,
                                etc., and
                                by no means aims to override any applicable law or regulatory guidance. When there is
                                any conflict or
                                inconsistency between this Code of Conduct and any law or regulation, such law or
                                regulation will prevail.
                            </li>
                            <li>
                                This Code of Conduct is subject to review by the board of directors of DLAI (“Board”)
                                from time to time,
                                [which review shall be conducted at least once in every calendar year].
                            </li>
                        </ol>
                    </li>
                    <li class="hide">
                        <p class="bold">III. Adherence with the Digital Lending Guidelines (Regulatory Framework for
                            Digital Lending)</p>
                        <ol>
                            <li>
                                The fund flow must be directly between the borrower and the RE (except for disbursals
                                covered exclusively
                                under statutory or regulatory mandate). The funds cannot flow through the account of a
                                lending service
                                provider or a digital lending application. Similarly, any fees, charges, etc. payable to
                                the lending service
                                provider shall be paid by the REs and no funds shall flow from the borrower to the
                                lending service provider.
                                (Paragraphs 3 and 4)
                            </li>
                            <li>
                                REs shall provide a key fact statement to the borrower before execution of the contract
                                (which must be in
                                the prescribed format) for all digital lending products. The key fact statement shall
                                contain details of the
                                annual percentage rate (which must be disclosed upfront as an all-inclusive cost of the
                                digital loan to the
                                borrower), recovery mechanism, details of grievance redressal officer, cooling-off
                                period, penal interest or
                                charges (if any, based on the outstanding amount of the loan) levied on the borrowers.
                                Fees or charges not
                                mentioned in the key fact statement cannot be charged by the REs at any stage.
                                (Paragraphs 4.2, 5.1, and
                                5.2)
                            </li>
                            <li>
                                The REs shall ensure that in the absence of physical documents, the digitally signed
                                documents on the
                                letterhead of the RE shall automatically flow to the registered and verified email/SMS
                                of the borrower upon
                                execution of the loan contract. (Paragraph 5.3)
                            </li>
                            <li>
                                The list of lending service providers of the REs, the digital lending applications of
                                the REs, and their
                                lending service providers or any other party (e.g., In-app advertising) shall be
                                prominently published on
                                the website of the REs along with details of activities for which they have been
                                engaged. (Paragraph 5.4)
                            </li>
                            <li>
                                The digital lending applications of both the REs and their lending service providers
                                shall prominently
                                display information relating to product features, loan limit, cost, etc., at the
                                sign-up/on-boarding stage
                                and must also have links to the REs’ website where detailed information about the loan
                                products, the lender,
                                the lending service providers, particulars of the customer care details, link to the
                                RBI’s Sachet Portal,
                                privacy policy, etc., prominently provided at a single place can be easily accessed by
                                the borrower.
                                (Paragraphs 5.5 and 5.7)
                            </li>
                            <li>
                                The REs shall inform the borrower about the details of the lending service provider
                                and/or any other
                                intermediary acting as a recovery agent and authorized to approach the borrower for
                                recovery, at the time of
                                sanctioning the loan amount and while appointing a lending service provider as a
                                recovery agent or in case
                                of a change in recovery agent. (Paragraph 5.6)
                            </li>
                            <li>
                                REs shall ensure that they and their lending service providers have a nodal grievance
                                redressal officer to
                                deal with fintech/digital lending-related and digital lending application-related
                                complaints raised by the
                                borrower. The REs’ and their lending service providers’ websites and their digital
                                lending applications and
                                the key fact statement shall prominently display the contact details of the grievance
                                redressal officer. The
                                REs’ and their lending service providers’ websites and their digital lending
                                applications shall provide the
                                facility to lodge complaints. The responsibility of grievance redressal shall remain
                                with the REs, and if
                                the complaint is not resolved within 30 days, the borrower can lodge a complaint over
                                the Complaint
                                Management System portal under the RBI Ombudsman Scheme. (Paragraph 6)
                            </li>
                            <li>
                                REs shall capture the economic profile of the borrowers before extending any loan to
                                assess the borrower’s
                                creditworthiness. REs shall also ensure that the credit limit of the borrower is not
                                increased without
                                explicit consent taken on record. (Paragraph 7)
                            </li>
                            <li>
                                The borrower shall be given an explicit option to exit the digital loan by paying the
                                principal and
                                proportionate annual percentage rate without any additional penalty during the
                                cooling-off/looking-up
                                period. The Board of the RE shall determine the duration of the cooling-off period,
                                which must be at least
                                three days for loans having a duration of seven days and a maximum of one day for loans
                                having a duration
                                less than seven days. Pre-payment shall be allowed to borrowers continuing after the
                                cooling-off period as
                                per RBI extant guidelines. (Paragraph 8)
                            </li>
                            <li>
                                REs shall conduct enhanced due diligence with respect to technical abilities, data
                                privacy policies and
                                storage systems, fairness in conduct with borrowers, and ability to comply with
                                regulations and statutes
                                before entering into a partnership with a lending service provider for digital lending.
                                REs shall carry out
                                periodic reviews of the conduct of their lending service providers and impart necessary
                                guidance to the
                                lending service providers acting as recovery agents to discharge their duties
                                responsibly and in compliance
                                with extant instructions. (Paragraph 9)
                            </li>
                            <li>
                                REs shall ensure that any collection of data by the REs’ or their lending service
                                providers’ digital lending
                                applications is need-based and on explicit prior consent of the borrower having an audit
                                trail. REs shall
                                ensure that the digital lending applications desist from accessing mobile phone
                                resources like file and
                                media, contact list, call logs, telephony functions, etc. One-time access for camera,
                                microphone, location,
                                etc., necessary for on-boarding/KYC requirements may be taken with the explicit consent
                                of the borrower.
                                (Paragraph 10.1)
                            </li>
                            <li>
                                The borrower shall be provided with an option to give or deny consent for the use of
                                specific data, restrict
                                disclosure to third parties, data retention, revoke consent already granted to collect
                                personal data, and
                                make the application delete/forget the data. (Paragraph 10.2)
                            </li>
                            <li>
                                The purpose of obtaining borrowers’ consent needs to be disclosed at each stage of
                                interface with the
                                borrowers. (Paragraph 10.3)
                            </li>
                            <li>
                                Explicit consent of the borrower shall be taken before sharing personal information with
                                any third party,
                                except for cases where such sharing is required as per statutory or regulatory
                                requirements. (Paragraph
                                10.4)
                            </li>
                            <li>
                                REs are also required to ensure that (a) the lending service providers they engage with
                                do not store the
                                personal information of borrowers except some basic minimal data (viz., name, address,
                                contact details of
                                the customer, etc.) that may be required to carry out their operations; (b) clear policy
                                guidelines
                                regarding the storage of customer data including the type of data that can be stored,
                                the length of time for
                                which data can be stored, restrictions on the use of data, data destruction protocol,
                                standards for handling
                                security breaches, etc., are put in place; (c) the data sets that are collected by a
                                lending service
                                provider or a digital lending application is disclosed prominently on its website and
                                application at all
                                times; and (d) each lending service provider that it partners with has a comprehensive
                                privacy policy in
                                place that is in compliance with applicable laws, rules, and regulations. (Paragraph 11)
                            </li>
                            <li>
                                REs must ensure that all data is stored only on servers located in India. Further, REs
                                must also ensure that
                                no biometric data of any customer is stored by any lending service provider or any
                                digital lending
                                application. (Paragraph 11)
                            </li>
                            <li>
                                REs shall ensure that their digital lending applications and lending service providers
                                engaged by them have
                                a comprehensive privacy policy (including details of third parties allowed to collect
                                personal information
                                through the digital lending applications) compliant with applicable laws, associated
                                regulations, and RBI
                                guidelines. For access and collection of personal information of borrowers, digital
                                lending applications of
                                REs/lending service providers should make the comprehensive privacy policy available
                                publicly. (Paragraph
                                12)
                            </li>
                            <li>
                                REs shall ensure that they and their lending service providers comply with various
                                technology
                                standards/requirements on cybersecurity stipulated by RBI and other agencies, or as may
                                be specified from
                                time to time, for undertaking digital lending. (Paragraph 13)
                            </li>
                            <li>
                                REs shall ensure that any lending done through their digital lending application and/or
                                digital lending
                                applications of their lending service providers is reported to credit information
                                companies irrespective of
                                its nature/tenor. REs shall ensure that lending service providers associated with such
                                deferred payment
                                credit products shall abide by the extant outsourcing guidelines issued by the RBI.
                                (Paragraph 14)
                            </li>
                        </ol>
                    </li>
                    <li class="hide">
                        <p class="bold">IV. Code of Conduct</p>
                        <p>The Code of Conduct is centered around 8 (eight) core elements:</p>
                        <p class="bold">A. Transparency and Disclosures</p>
                        <ol>
                            <li>
                                Every Member must display the Code of Conduct as part of their fair practices code
                                (similar to Banks and
                                NBFCs) at the point of customer interface – must be on the Member’s website (if any) and
                                the digital lending
                                application (app) through which the lending activities are undertaken by the Member.
                            </li>
                            <li>
                                Members must offer products and services that are not misleading, deceptive, or unclear.
                                Members must ensure
                                that their marketing and advertising material and outreach to customers is not false,
                                misleading, or
                                deceptive.
                            </li>
                            <li>
                                Members must provide relevant information in a key fact statement in a standardized
                                format and in a language
                                that their customers would reasonably understand.
                            </li>
                            <li>
                                Members must ensure that detailed terms and conditions of the financial product and
                                services offered, loan
                                limits and cost, etc. are made available to the customer at the onboarding/ customer
                                engagement stage.
                            </li>
                            <li>
                                Members must ensure that digital and/or physically signed copies of the key fact
                                statement, summary of loan
                                product, sanction letter, loan agreement, and terms and conditions, applicable to the
                                credit products being
                                availed by a borrower, are automatically provided to the borrowers on their registered
                                and verified email
                                address and over SMS, on or before disbursement of the loan.
                            </li>
                            <li>
                                All REs must prominently publish on their website a list of their latest version of
                                digital lending
                                applications, their active lending service providers (LSP), and the latest version of
                                digital lending
                                applications of their lending service providers. Every Member must ensure that their
                                names and contact
                                details of active LSPs with customer interface appear on the website of each of the REs
                                they are engaged
                                with.
                            </li>
                            <li>
                                It must be clearly disclosed to the customers that they are taking a loan and that this
                                will have
                                consequences in terms of credit bureau reporting and potential legal action in case of
                                an event of default.
                            </li>
                            <li>
                                The customer must understand that they have an obligation to repay the loan and the
                                exact consequences of
                                non-payment or delayed payment.
                            </li>
                            <li>
                                The customer must understand who the exact loan provider on record is (The RE) and who
                                will be collecting
                                repayments in connection with the loan. In the event the Member is not the lender on
                                record, the customer
                                should understand the role and responsibility of the Member in the process and
                                transaction relating to the
                                provision of financial products and services.
                            </li>
                            <li>
                                The Member must clearly mention an annual percentage rate in its key fact statement
                                which includes and lists
                                all costs and fees applicable to the financial product or service offered, including all
                                upfront fees,
                                processing fees, interest costs, insurance costs, registration fees, provisions,
                                re-arrangement fees, late
                                fees, pre-payment fees or penalties, and any other costs charged to the customer.
                            </li>
                            <li>
                                The illustration of all costs, including any contingent or default costs and expenses,
                                must be explicit and
                                clear and provided in a manner that can be understood by the customer.
                            </li>
                            <li>
                                Members must provide illustrative examples of the costs to the customer, including any
                                contingent or default
                                costs, in INR format specific to the financial service or product offered, so that the
                                customer understands
                                all such costs.
                            </li>
                            <li>
                                Members must provide a repayment schedule with detailed repayment information and due
                                dates in a clear
                                manner. The Member must provide timely information about loan payments due and
                                outstanding loan amounts in a
                                format that the customer clearly understands.
                            </li>
                            <li>
                                Members must inform the borrowers of such a look-up period offered to customers in
                                accordance with clause 5
                                (Look-up period) of Part B (Responsible Lending) of this Section IV to repay the digital
                                loan on their
                                websites/digital lending applications at the time of execution of the loan
                                contract/transactions.
                            </li>
                            <li>
                                Members must provide a link on their website/digital lending application to direct
                                customers to a webpage of
                                the RE containing detailed information surrounding the financial products – namely about
                                the loan products,
                                the lender, the lending service provider, particulars of customer care, link to the
                                RBI’s Sachet portal,
                                privacy policies, etc.
                            </li>
                        </ol>


                        <p class="bold">B. Responsible Lending</p>
                        <ol>
                            <li>The onus is always on the Member to make fair income and affordability assessments of
                                customers and ensure
                                that financial products and services, including the loan and all charges and fees, are
                                not in excess of a
                                customer’s capacity to pay.</li>
                            <li>
                                Members must capture an economic profile of each borrower (covering age, occupation,
                                income, etc., or any
                                other borrower data collected which has a direct and tangible link with the economic
                                profiling of the
                                borrower), enabling credit decision-making by the REs, before extending any loan to any
                                borrower to assess
                                the borrower’s creditworthiness. Members must ensure that the credit decision-making
                                rationale is auditable
                                and the data collected is subject to the conditions contained in Part D (Data Security
                                and Privacy) of this
                                Section IV below.
                            </li>
                            <li>Members cannot increase their credit limit automatically. An explicit consent of the
                                borrower must be taken
                                on record for each such increase in the credit limits.</li>
                            <li>Members will not design pricing models that could ever be considered “predatory” or
                                “usurious,” including
                                but not limited to:</li>
                            <ol>
                                <li class="hide">(a) any element of deception in the presentation of costs or fees;</li>
                                <li class="hide">(b) cost or fee structures that are needlessly confusing, complex, and
                                    designed to
                                    extract maximum
                                    revenue without consideration of customer understanding.</li>
                            </ol>
                            <li>Look up period:</li>
                            <ol>
                                <li class="hide">(a) Members must (in accordance with the timelines decided by the board
                                    of directors
                                    of their associated
                                    REs) give the borrowers an exit opportunity – to exit the digital lending product
                                    availed by paying the
                                    principal amount and the proportionate annual percentage rate (without any penalty);
                                </li>
                                <li class="hide">(b) Such look-up period provided by each Member must not be less than 3
                                    (three) days
                                    for a loan of tenor
                                    exceeding 7 (seven) days and not be less than 1 (one) day otherwise if the loan is
                                    of tenor less than 7
                                    (seven) days;</li>
                                <li class="hide">(c) Members must inform the borrowers of such look-up period to repay
                                    the digital
                                    loan on their
                                    websites/ digital lending applications at the time of execution of the loan
                                    contract/transactions; and
                                </li>
                                <li class="hide">(d) In any case, the Members must allow prepayment of the digital loans
                                    even beyond
                                    the look-up period
                                    as per extant RBI guidelines.</li>
                            </ol>
                            <li>Members must clearly mention an annual percentage rate in its key fact statement which
                                includes and lists
                                all costs and fees arising from the financial product or service offered, including all
                                upfront fees,
                                processing fees, interest costs, insurance costs, registration fees, provisions,
                                re-arrangement fees, late
                                fees, pre-payment fees or penalties, and any other costs charged to the customer.</li>
                            <li>Members must provide clear information related to the amount and mechanism of imposing
                                fines in the event of
                                a delay. Such information should be disclosed upfront to the customer in the key fact
                                statement. A Member
                                cannot impose on the customers any fines, charges, costs, etc., which are not disclosed
                                in the key fact
                                statement.</li>
                            <li>Members must ensure that the late payment penalties levied are reasonable and
                                transparent, non-compounding,
                                and must be levied only on the remaining outstanding value of the loan, in accordance
                                with the policies of
                                the Member framed in this regard.</li>
                            <li>Members must have a system and process of verification and assessment of the financial
                                condition of the
                                customer to assess the eligibility and suitability for the loan or other financial
                                product offered.</li>
                            <li>Members must have a system to ensure the accuracy of the data and information provided
                                by a customer.</li>
                        </ol>

                        <p class="bold">C. Fair Interactions</p>
                        <ol>
                            <li>Members shall either perform the recovery function in-house or engage an accredited
                                recovery agent.</li>
                            <li>Members must ensure that borrowers are not unfairly discriminated against on grounds
                                such as religion,
                                caste, gender, marital status, sexual orientation, etc.</li>
                            <li>Members must ensure that there is no undue harassment or intimidation (physical or
                                verbal) of customers,
                                including practices such as calling (or threatening to call) any family member of the
                                customer or any person
                                associated with the customer, sending inappropriate messages either on mobile or through
                                social media,
                                making threatening and/or anonymous calls, etc. The Members must ensure that there is no
                                coercion in the
                                recovery process.</li>
                            <li>Members must ensure that its staff, agents, and representatives are adequately trained
                                to deal with the
                                customers in an appropriate manner and are not rude or humiliating in their dealings
                                with the customer.
                                Members must ensure compliance with the extant RBI guidelines.</li>
                            <li>Members must ensure that its staff, agents, and representatives use respectful language,
                                maintain decorum,
                                and show respect to social and cultural sensitivities. Further, its staff, agents, and
                                representatives must
                                not contact borrowers at odd hours or at inappropriate times such as during bereavement,
                                illness, or social
                                occasions such as marriages and births.</li>
                            <li>Members must ensure that their staff, agents, and representatives contact their
                                borrowers only during normal
                                hours (between 8:00 a.m. and 7:00 p.m.) for the recovery of overdue loans.</li>
                            <li>Members must ensure that the recovery agent contacts the customer for recovery only
                                after the associated RE
                                has, at the time of sanctioning the loan and at the time of allocating the recovery
                                responsibilities, shared
                                the name and details of such Member/recovery agent with the customers.</li>
                        </ol>


                        <p class="bold">D. Data Security and Privacy</p>
                        <ol>
                            <li>Members must have a board-approved comprehensive data privacy policy compliant with
                                applicable laws,
                                associated regulations, and RBI guidelines disseminated publicly on its website/digital
                                lending application
                                and further, at every stage where consent of the borrower is taken to access the data of
                                the customer. Such
                                privacy policy must inter alia clearly outline the type of data that can be stored, the
                                length of time for
                                which data can be stored, restrictions on the use of data, data destruction protocol,
                                standards for handling
                                security breaches, and the details of third parties (if any) who are allowed to collect
                                personal information
                                of the customers through the digital lending application of such Member.</li>

                            <li>Members must follow a consent-based architecture for data capture with informed consent
                                provided by the
                                customer following a detailed explanation of the data being captured and used (including
                                sharing of such
                                data with third parties). The Member shall preserve such digital records of customer
                                consent(s) as proof of
                                informed consent.</li>

                            <li>Members are required to practice good faith in the collection, storage, use, and sharing
                                of personal data of
                                customers. Without limiting the generality of the above, Members shall not:</li>
                            <ol>
                                <li class="hide">(a) intentionally request personal data from a customer even though
                                    there is no
                                    service that can be
                                    provided to a customer;</li>
                                <li class="hide">(b) intentionally collect personal data that is not relevant to the
                                    services that
                                    will be provided to
                                    the customer by the Member;</li>
                                <li class="hide">(c) collect personal data outside the data that has been agreed to be
                                    given by the
                                    customer;</li>
                                <li class="hide">(d) use personal data for purposes that have not been notified or
                                    purposes that are
                                    different from what
                                    was previously notified to the customer;</li>
                                <li class="hide">(e) collect and store customer’s personal data even though the Member
                                    or any person
                                    authorized by the
                                    Member to collect, process or store such personal data does not yet have a reliable
                                    system or processes
                                    to carry out such activities or protect such data;</li>
                                <li class="hide">(f) sell personal data of customers to third parties without explicit
                                    consent from
                                    the customer;</li>
                                <li class="hide">(g) share such personal data with third parties other than for purposes
                                    consented to
                                    by the customer or
                                    where it is required under applicable law;</li>
                                <li class="hide">(h) use a customer’s contact list for contacting any third parties,
                                    family members
                                    of the customer, or
                                    persons associated with the customer for any communication related to the customer’s
                                    loan;</li>
                                <li class="hide">(i) use the personal data in any manner which is likely to cause
                                    physical harm or
                                    injury to any
                                    customer, their family member, or any person associated with the customer.</li>
                            </ol>

                            <li>Members may access, store, and utilize the personal information of the borrower provided
                                that:</li>
                            <ol>
                                <li class="hide">(a) the Member can justify that a certain data set is needed in
                                    connection with its
                                    operations or to
                                    perform a certain function for and on behalf of an RE partner (under an agreement);
                                </li>
                                <li class="hide">(b) the Member is able to demonstrate a tangible and direct link
                                    between the
                                    borrower data collected and
                                    the economic profiling of the borrower enabling credit decision-making by the RE.
                                    Such credit
                                    decision-making rationale must be auditable;</li>
                                <li class="hide">(c) the data is collected with the explicit consent of the borrower.
                                    Such borrower
                                    consents must be
                                    recorded by the Member in a manner that is auditable;</li>
                                <li class="hide">(d) the user interface of the digital lending application of a member
                                    must not
                                    facilitate ‘umbrella
                                    consent’ or ‘consent fatigue’. Instead, the Member must obtain informed consent of
                                    the borrowers whereby
                                    the borrower is clearly made aware of the data points that will be accessed. The
                                    Member must obtain
                                    upfront consent of its customers for the collection, storage, utilization, and
                                    sharing of any borrower
                                    information;</li>
                                <li class="hide">(e) Members must not obtain over-arching access to a borrower’s mobile
                                    phone
                                    resources like files and
                                    media, contact list, call logs, or telephony functions. Any such access must be
                                    strictly need-based and
                                    related to the products or services proposed to be provided to the customers;</li>
                                <li class="hide">(f) the purpose of the collection of data and taking the consent of the
                                    borrower is
                                    clearly disclosed by
                                    the Member to the borrower at each stage;</li>
                                <li class="hide">(g) the borrower is given the option to both give and deny consent for
                                    the use of
                                    specific data,
                                    restrict disclosure of such data to third parties by Member, revoke previously
                                    granted consent to access
                                    personal data, limit the time period for which such data can be stored by the
                                    Member, and require the
                                    Member to delete the data collected from the borrower;</li>
                                <li class="hide">(h) the data collected is only processed and used for the limited
                                    purpose disclosed
                                    to the borrower;
                                </li>
                                <li class="hide">(i) Only minimal customer data that is personal in nature is stored by
                                    the Member,
                                    which is critical for
                                    the Member to carry out its operations and functions as required by the RE under the
                                    terms of the
                                    partnership arrangement;</li>
                                <li class="hide">(j) the data is collected and stored by the Member only in the capacity
                                    of a lender
                                    service
                                    provider/digital lending application, for associated RE, and not in any other
                                    capacity (such as for the
                                    purpose of providing value-added services to borrowers, which services are not
                                    directly linked to
                                    digital lending);</li>
                                <li class="hide">(k) the Member shall not store any biometric information of any
                                    customer, other than
                                    as permitted under
                                    extant statutory guidelines;</li>
                                <li class="hide">(l) all data collected by the Member is stored only on servers located
                                    in India;
                                </li>
                                <li class="hide">(m) the technology deployed by the Member complies with the technology
                                    standards/requirements on
                                    cybersecurity stipulated by the RBI and other agencies, for undertaking digital
                                    lending; and</li>
                                <li class="hide">(n) Members may, in the capacity of the agent of the customer, seek the
                                    credit
                                    information of the
                                    customer from the credit information companies, by providing satisfactory
                                    identification along with the
                                    consent of the customer to obtain such information on the customer’s behalf.</li>
                            </ol>
                        </ol>



                        <p class="bold">E. Customer Grievance Redressal</p>
                        <ol>
                            <li>Each Member should have a board-approved policy for addressing customer complaints in a
                                fair and prompt
                                manner covering the process to register, resolve and escalate the complaints, internal
                                and external
                                escalation mechanism, turnaround time, complaint categories, review/audit of redressal
                                system, and reporting
                                to the board and top management. Each Member shall put into place an efficient mechanism
                                for the
                                implementation of such customer complaint policy and for the resolution of customer
                                complaints in compliance
                                with the contractual and statutory rights of the customer.</li>

                            <li>Each Member must appoint a suitable nodal grievance redressal officer to oversee the
                                customer grievance
                                redressal function. Every Member must prominently display the contact details of such
                                nodal grievance
                                redressal officer on their website, the website of the RE/the lending service provider,
                                the digital lending
                                application, and in the key fact statement.</li>

                            <li>Each Member shall provide to customers, including by publishing on its website, details
                                of how the customer
                                can contact customer service/concerned compliance officer at the Member or seek
                                redressal of customer
                                complaints. Each Member should also provide details of how customers can contact the
                                REs, which are involved
                                in providing the relevant financial product or services to the customer.</li>

                            <li>Each Member must also publicize the following details of the customer grievance
                                redressal mechanism set up
                                by DLAI:
                                <ol>
                                    <li class="hide">(a) DLAI’s email ID for customer grievances: <a
                                            href="mailto:cgrm@dlai.in">cgrm&#64;dlai.in</a></li>
                                </ol>
                            </li>

                            <li>Members should also provide details of the right of the customers to raise complaints in
                                consumer forums,
                                under the Reserve Bank-Integrated Ombudsman Scheme (“RBI Ombudsman”), etc. and guidance
                                on how the customer
                                can approach such authorities.</li>

                            <li>Members must have a mechanism as part of their grievance redressal framework for the
                                redressal of
                                recovery-related grievances, the details of which must be provided to the borrower in
                                the key fact
                                statement. It shall be sufficient compliance if the Member can re-structure/re-organize
                                its existing
                                redressal system to identify and promptly address recovery-related grievances.</li>

                            <li>Members must record and analyse individual and aggregate level data for the grievance
                                redressal system
                                capturing the nature of complaints, action taken, and turn-around time. Report on
                                grievances received,
                                resolved, and pending along with the nature of complaints should be reported quarterly
                                to DLAI and must be
                                placed before the board of directors of the Member for review, from time to time.</li>
                        </ol>



                        <p class="bold">F. Employee Training</p>
                        <ol>
                            <li>The Members must give comprehensive induction training to the employees on policies,
                                processes, and
                                regulations. Emphasis should be given to Code of Conduct-related aspects on
                                borrower-interface aspects such
                                as fair treatment, the privacy of data, service quality, customer grievance redressal
                                system, POSH,
                                relationship management, dealing with difficulty in repayment, etc.</li>

                            <li>The Members must regularly assess employees’ understanding of the above elements and
                                conduct
                                refresher
                                training to address the gaps in understanding.</li>

                            <li>The Members must train their employees on understanding and dealing with gender issues
                                including appropriate
                                interaction with women colleagues and customers.</li>

                            <li>The Members must necessarily orient their employees on professional conduct and
                                integrity
                                issues including
                                expected behaviour and not indulging in any unlawful and anti-social activities.</li>

                            <li>The Members must engage new employees in the business operations only after completion
                                of
                                their induction
                                training.</li>

                            <li>The Members must ensure that employees directly responsible for the grievance redressal
                                system receive
                                detailed training about the system, processes, and soft skills required for resolving
                                complaints.</li>

                            <li>The Members must set targets for the recovery agents based on a reasonable object
                                criterion
                                including an
                                understanding of the repayment capacity of the customer.</li>

                            <li>The Members must ensure that the compensation matrix for the recovery staff should not
                                solely be dependent
                                on the quantum of recovery by an individual, and rather, it must be designed in a manner
                                to
                                align their
                                behavior with fair interaction practices as mentioned in Part C (Fair Interaction) of
                                this
                                Section IV of
                                this Code of Conduct.</li>
                        </ol>



                        <p class="bold">G. Customer Awareness</p>
                        <ol>
                            <li>Each Member must take measures to ensure that borrowers fully understand the products,
                                process, and terms of
                                the contract. Such measures must be provided to the borrowers free of cost.</li>

                            <li>Each Member must provide a receipt/acknowledgement for every payment, including the
                                digital
                                payments,
                                received from the borrower.</li>

                            <li>Each Member must give emphasis to educating customers on the importance of timely
                                repayment
                                for good credit
                                history with the Credit Information Companies, benefits, risks, and necessary safeguards
                                of
                                digital
                                financial transactions and grievance redressal mechanism including internal and external
                                escalation
                                mechanism.</li>
                        </ol>



                        <p class="bold">H. Governance and Enforcement</p>
                        <ol>
                            <li>Obligation to adhere to all applicable regulations in letter and spirit.</li>

                            <li>Each Member will comply with all provisions of all applicable laws and regulations,
                                including, but not limited to:
                                <ol>
                                    <li class="hide">(a) Applicable laws and regulations concerning financial services
                                        and consumer
                                        protection, including without limitation all directions, guidelines, circulars,
                                        and notifications issued by the Reserve Bank of India and other relevant
                                        statutory, regulatory, or government bodies;</li>
                                    <li class="hide">(b) Applicable laws and regulations in the field of communication
                                        and
                                        informatics related to the protection of personal data in electronic systems;
                                    </li>
                                    <li class="hide">(c) Any other applicable law and regulations relating to business,
                                        operations,
                                        and practices of such Member.</li>
                                </ol>
                            </li>

                            <li>All provisions in this Code of Conduct are complementary and in addition to the
                                obligations of each Member under laws and regulations applicable to the Member. Each
                                Member is individually and solely responsible for its compliance with applicable laws,
                                regulations, and this Code of Conduct.</li>

                            <li>Obligation to adhere to this Code of Conduct:
                                <ol>
                                    <li class="hide">(a) At the time of availing membership with the DLAI, the Members
                                        must affirm in
                                        writing, backed by a board resolution of the company, to adhere to the Code of
                                        Conduct.</li>
                                    <li class="hide">(b) Provided that existing Members will pass such board resolution
                                        affirming
                                        adoption of the Code of Conduct within [1 (one) month from the Effective Date]
                                        for continuation of their membership.</li>
                                    <li class="hide">(c) Each Member will nominate a designated compliance officer from
                                        within their
                                        organisation, who will be the point of contact for all correspondences
                                        (including reporting) with the DLAI (“Designated Compliance Officer”). The name
                                        and correspondence details of the Designated Compliance Officer must be
                                        intimated to the DLAI immediately upon nomination, and in any case, not later
                                        than 3 (three) days.</li>
                                    <li class="hide">(d) Each member shall follow advisories/Directives any other
                                        communication of
                                        sectoral importance, issued time to time by DLAI.</li>
                                    <li class="hide">(e) Each member institution shall share with DLAI the data and
                                        information
                                        requested time to time for sectoral publications, research and/or as may be
                                        required by any Government agency/Law.</li>
                                    <li class="hide">(f) The Designated Compliance Officer must ensure that this Code of
                                        Conduct (as
                                        updated from time to time) is disseminated within the organization of the Member
                                        after any update to the Code of Conduct, and in any case, within a period not
                                        exceeding 1 (one) year.</li>
                                </ol>
                            </li>

                            <li>Reporting:
                                <ol>
                                    <li>(a) Ad-hoc:
                                        <ol>
                                            <li class="hide">(i) In the event of any non-adherence by a Member of the
                                                Code of Conduct
                                                or any applicable law, such Member may voluntarily self-report such
                                                non-compliance to the DLAI (SRO) secretariat immediately and in any case
                                                within 7 (seven) days of such non-adherence.</li>
                                            <li class="hide">(ii) In the event of the occurrence of any cyber security
                                                incidents
                                                affecting any Member, such Member must (and in any case within 6 (six)
                                                hours of noticing such incidents or being brought to notice about such
                                                incidents) report such incidents to Cert-In and to the DLAI (SRO)
                                                Secretariat.</li>
                                        </ol>
                                    </li>

                                    <li>(b) Quarterly:
                                        <ol>
                                            <li class="hide">(i) Each Member must record and analyze individual and
                                                aggregate level
                                                data for a grievance redressal system capturing the nature of
                                                complaints, action taken, and turn-around time. Report on grievances
                                                received, resolved, and pending along with the nature of complaints
                                                should be reported on a quarterly basis to the DLAI, within 15 (fifteen)
                                                days from the end of each quarter.</li>
                                        </ol>
                                    </li>

                                    <li>(c) Annually:
                                        <ol>
                                            <li class="hide">(i) Each Member must submit an annual confirmation to the
                                                DLAI (SRO)
                                                Secretariat, in writing, on its compliance with the Code of Conduct, in
                                                such form as the DLAI (SRO) may require from time to time (“Annual
                                                Submission”). The Annual Submission will include a certification by a
                                                director, company secretary, or other key managerial personnel of the
                                                Member that it is in compliance with the Code of Conduct, the Digital
                                                Lending Guidelines, and other applicable laws/regulations. The Annual
                                                Submission will be required from each Member in order to continue its
                                                membership and participation in DLAI activities.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>

                            <li>Dispute Resolution:
                                <ol>
                                    <li>(a) Consultation:
                                        <ol>
                                            <li class="hide">(i) In case of any dispute inter se Members with regards to
                                                elements
                                                mentioned in DLAI’s Industry COC, the Members hereby agree to first
                                                afford adequate opportunity for consultation regarding any
                                                representations made by another Member.</li>
                                            <li class="hide">(ii) If a request for consultation is made, the Member to
                                                which the
                                                request is made shall, unless otherwise mutually agreed, reply to the
                                                request within 10 (ten) working days after the date of its receipt and
                                                shall enter into consultations in good faith within a period of no more
                                                than 30 (thirty) days after the date of receipt of the request, with a
                                                view to reaching a mutually satisfactory solution.</li>
                                            <li class="hide">(iii) If the Member does not respond within 10 (ten)
                                                working days after
                                                the date of receipt of the request or does not enter into consultations
                                                within a period of no more than 30 (thirty) days, or a period otherwise
                                                mutually agreed, after the date of receipt of the request, then the
                                                Member that requested the holding of consultations may proceed directly
                                                to request the Enforcement Committee to decide such matters.</li>
                                            <li class="hide">(iv) Consultations shall be confidential, and without
                                                prejudice to the
                                                rights of any Member in any further proceedings.</li>
                                        </ol>
                                    </li>

                                    <li>(b) Arbitration:
                                        <ol>
                                            <li class="hide">(i) If: (A) the consultations fail to settle a dispute
                                                within 60 (sixty)
                                                days after the date of receipt of the request for consultations and the
                                                Members agree that the dispute be settled by arbitration, or (B) if the
                                                contractual arrangements governing the relationship between the parties
                                                provide for arbitration, the Members may request the disputes be
                                                referred to arbitration.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>

                            <li>Sanctions:
                                <ol>
                                    <li class="hide">(a) The DLAI (SRO) will monitor compliance with the Code of Conduct
                                        by Members.
                                        Additionally, an anonymous peer complaints system whereby Members can bring
                                        forth instances of non-adherence (details to be submitted with evidence to DLAI)
                                        with the Code of Conduct or applicable law by other Members to the notice of the
                                        DLAI.</li>

                                    <li class="hide">(b) The Enforcement Committee will notify a fair procedure for the
                                        admission of
                                        complaints against any Member for the violation of the Code of Conduct,
                                        investigation, and determination of a violation of the Code of Conduct, and the
                                        decision on an application of Sanctions against the non-compliant Member after
                                        giving reasonable opportunity to such Member to make representations in such a
                                        process.</li>

                                    <li class="hide">(c) Any decision taken by the Enforcement Committee on
                                        non-compliance with the
                                        Code of Conduct will be binding on the relevant Member and will be subject to
                                        appeal to the SRO Committee. The decision of the SRO Committee will be final.
                                    </li>

                                    <li class="hide">(d) The Enforcement Committee will be entitled to take the
                                        following actions for
                                        non-compliance by a Member with the Code of Conduct or other applicable law,
                                        after providing an opportunity of hearing to such Member(s) (“Sanctions”) and
                                        allowed to rectify the non-compliance with a warning letter, if non-compliance
                                        still persists:
                                        <ol>
                                            <li class="hide">(i) cancellation of its membership with DLAI.</li>
                                            <li class="hide">(ii) bar the non-compliant Member from future membership of
                                                DLAI
                                                participating in its events, and/or from forming part of the Board or
                                                any of the committees of the DLAI for such a period of time as the
                                                Enforcement Committee may deem fit.</li>
                                            <li class="hide">(iii) notify all other Members of the abeyance of the
                                                membership and
                                                debarment of the non-compliant Member, and to also publish the fact of
                                                such abeyance and debarment in a ‘grey list of non-compliant digital
                                                lending entities’ maintained by DLAI in its records which may or may not
                                                be publicly available. The placement of the Member in the grey list will
                                                be seen as having an adverse impact on the reputation of such removed
                                                Members and customers will be encouraged not to undertake financial
                                                products from such non-compliant Members placed in the grey list.</li>
                                        </ol>
                                    </li>

                                    <li class="hide">(e) Upon receiving a formal request from an entity placed in the
                                        grey list, such
                                        grey list will be reviewed periodically by the Enforcement Committee of DLAI and
                                        those entities which are in the list, if demonstrate compliance to the
                                        satisfaction of EC & SROC, may be removed from the list after three months, with
                                        the approval of SRO Committee on case-to-case basis.</li>

                                    <li class="hide">(f) Provided that the DLAI (SRO) secretariat shall monitor the
                                        status of
                                        non-compliance of every Member placed on such grey list. May make an application
                                        every 1 (one) month.
                                        <ol>
                                            <li class="hide">(i) report any serious violation of the Code of Conduct to
                                                the
                                                appropriate authorities, including the RBI;</li>
                                            <li class="hide">(ii) Issue warning letter(s) on a Member in case such
                                                non-adherence by a
                                                Member has severe detrimental implications for customer protection
                                                measures and the industry;</li>
                                            <li class="hide">(iii) such other directions as the SRO Committee upon
                                                recommendation of
                                                the Enforcement Committee may consider fit for ensuring compliance with
                                                the Code of Conduct, including obtaining a binding commitment from the
                                                Member to take necessary remedial steps for compliance with the Code of
                                                Conduct.</li>
                                        </ol>
                                    </li>

                                    <li class="hide">(g) Within [21 (twenty-one) days] of receipt of the decision of the
                                        Enforcement
                                        Committee, the managing director of the Member has two options, either s/he is
                                        required to duly acknowledge the Enforcement Committee action with a commitment
                                        letter that the institution may take corrective steps to ensure that such
                                        non-compliance does not repeat in future except for reasons beyond human control
                                        like sudden technology malfunction/failure, the act of god, etc. Or s/he may
                                        appeal to SRO Committee whose decision will be final and binding. Evidence of
                                        corrective action, wherever necessary, must be taken from the Member.</li>

                                    <li class="hide">(h) Provided that the Sanctions above may be vacated by the
                                        Enforcement
                                        Committee if within [21 (twenty-one) days] of receipt of the decision of the
                                        Enforcement Committee, the non-adherence, and its consequences are, in the
                                        opinion of the Enforcement Committee, remedied pursuant to such corrective
                                        action.</li>

                                    <li class="hide">(i) If no response is received from the managing director of the
                                        Member within
                                        [21 (twenty-one) days] of receipt of the decision of the Enforcement Committee,
                                        the matter must be reported to the SRO Committee for further action which may
                                        include reporting to RBI by the DLAI (SRO).</li>

                                    <li class="hide">(j) The Enforcement Committee shall consider the following
                                        parameters in its
                                        decision:
                                        <ol>
                                            <li class="hide">(i) the regulatory and industry standards violated.</li>
                                            <li class="hide">(ii) the impact on customer protection.</li>
                                            <li class="hide">(iii) the systemic impact of such violation on the
                                                industry.</li>
                                            <li class="hide">(iv) the nature of the violation (procedural/policy,
                                                severity,
                                                magnitude, first-time/repeat);</li>
                                            <li class="hide">(v) the response of the Member, including whether the
                                                Member
                                                self-reported such non-compliance;</li>
                                            <li class="hide">(vi) age and size of the Member and duration of membership
                                                with DLAI to
                                                determine if the lapse is due to limited capacities and resources; and
                                            </li>
                                            <li class="hide">(vii) any other factor that the Enforcement Committee
                                                considers
                                                relevant.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>

                            <li>Appeal:
                                <ol>
                                    <li class="hide">(a) If the Member is aggrieved by the decision of the Enforcement
                                        Committee, an
                                        appeal may be filed before the SRO Committee, which must act in consultation
                                        with the Board, against the decision of the Enforcement Committee in writing and
                                        within [21 (twenty-one) days] from the date of receipt of the communication from
                                        the DLAI (SRO) regarding the decision of the Enforcement Committee.</li>

                                    <li class="hide">(b) If no appeal is filed within the above period, the order of the
                                        Enforcement
                                        Committee shall attain finality.</li>

                                    <li class="hide">(c) The decision of the SRO Committee taken in appeal will be final
                                        and binding
                                        on the Member. The SRO Committee must take into account the factors mentioned in
                                        sub-clause (g) of clause 5 (Sanctions) above in its decision.</li>
                                </ol>
                            </li>
                        </ol>
















                </ol>
            </div>
        </div>
    </div>

</div>