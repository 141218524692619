<div class="app-grievance-redressal-mechanism-policy">

    <div class="content">
        <!-- <div class="left-section">
            <div class="logo">
                <img src="https://assets.carefinance.xyz/Carewhitelogo.png" alt="Care Finance" title="Care Finance"
                    loading="lazy" />
            </div>
        </div> -->

        <div class="privacy-content">

            <h1 class="heading">Grievance Redressal Mechanism Policy</h1>
            <br>
            <br>
            <div class="paragraph">





                <ol>
                    <h4>CareFinance Healthcare App </h4>
                    <!-- <p class="bold"> Code of Conduct for Digital Lending -->
                    <!-- </p> -->


                    <li>
                        <p class="bold">Introduction</p>
                        <p>This Grievance Redressal Mechanism Policy ("Policy") outlines the process by which
                            CareFinance Healthcare App
                            ("CareFinance" or "Company") addresses and resolves grievances raised by its customers,
                            stakeholders, and any
                            other individuals affected by its services. The Policy is designed to ensure that complaints
                            are handled
                            promptly, fairly, and transparently, in accordance with applicable laws and regulations.</p>
                        <p>The objective is to provide a structured process for filing, escalating, and resolving
                            grievances to maintain
                            high customer satisfaction and trust.</p>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Scope</p>
                        <p>This Policy applies to:</p>
                        <ul>
                            <li>All users of the CareFinance platform, including borrowers, lenders, and other
                                customers.</li>
                            <li>Employees, agents, and third-party service providers associated with the Company.</li>
                            <li>Any individual or organization interacting with CareFinance in relation to its digital
                                lending services.
                            </li>
                        </ul>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Definitions</p>
                        <ol>
                            <ul>
                                <li>
                                    <p><strong>Grievance:</strong> A grievance is any dissatisfaction or complaint by a
                                        customer or stakeholder
                                        regarding the products, services, operations, or processes of CareFinance.</p>
                                </li>
                                <li>
                                    <p><strong>Complainant:</strong> The individual or entity raising the grievance.</p>
                                </li>
                                <li>
                                    <p><strong>Grievance Redressal Officer (GRO):</strong> The appointed officer
                                        responsible
                                        for addressing and
                                        resolving complaints in a fair and timely manner.</p>
                                </li>
                            </ul>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Objectives</p>
                        <ol>
                            <ul>
                                <li>To provide a transparent, accessible, and efficient process for addressing
                                    grievances.
                                </li>
                                <li>To ensure that all grievances are handled with fairness, respect, and
                                    confidentiality.
                                </li>
                                <li>To resolve grievances within a stipulated time frame in accordance with applicable
                                    laws
                                    and guidelines.</li>
                                <li>To improve our services and customer relationships by using feedback from
                                    grievances.
                                </li>
                            </ul>
                        </ol>

                    </li>
                    <br />
                    <li>
                        <p class="bold">Grievance Redressal Procedure</p>
                        <ol>
                            <li class="hide">
                                <p><strong>5.1. Filing a Grievance</strong></p>
                                <p>Customers can file their grievances through any of the following channels:</p>
                                <ul>
                                    <li><strong>Online Portal:</strong> A complaint can be submitted through the
                                        customer support section on
                                        the CareFinance app or website.</li>
                                    <li><strong>Email:</strong> Customers can send their complaints via email to
                                        shrikant&#64;cipherventurecapital.com.</li>
                                    <!-- <li><strong>Customer Support Number:</strong> Grievances can also be submitted
                                        through our toll-free
                                        number [Customer Support Number].</li> -->
                                    <li><strong>Postal Mail:</strong> Complaints can be addressed and mailed to:</li>
                                    <p>CareFinance Healthcare App,<br>FF- 6 A, Krishna Complex, Nr. Shayona
                                        Estate,<br>Memco Naroda Road, Ahmedabad,<br />Gujarat, India, 380025.

                                    </p>
                                </ul>
                                <p>The complaint must include the following details:</p>
                                <ul>
                                    <li>Full name of the complainant.</li>
                                    <li>Contact information (phone number and email).</li>
                                    <li>Detailed description of the grievance, including any relevant supporting
                                        documents (e.g., loan
                                        agreement, transaction history).</li>
                                    <li>Date of the incident or issue being raised.</li>
                                    <li>Any previous correspondence or complaint reference numbers.</li>
                                </ul>
                            </li>
                            <br />
                            <li class="hide">
                                <p><strong>5.2. Acknowledgment of Grievance</strong></p>
                                <p>Upon receipt of the grievance, an acknowledgment will be sent to the complainant via
                                    email, SMS, or mail
                                    within 24 hours. The acknowledgment will include:</p>
                                <ul>
                                    <li>A unique complaint reference number.</li>
                                    <li>The name and contact details of the designated Grievance Redressal Officer
                                        (GRO).</li>
                                    <li>Expected time frame for resolution of the complaint.</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Resolution Process</p>
                        <ol>
                            <li class="hide">
                                <p><strong>6.1. Initial Review</strong></p>
                                <p>The GRO will review the grievance within 2 business days of receipt and assign it to
                                    the relevant
                                    department or team for investigation. If any additional information or documentation
                                    is required from
                                    the complainant, the GRO will notify the complainant promptly.</p>
                            </li>
                            <li class="hide">
                                <p><strong>6.2. Investigation and Resolution</strong></p>
                                <p>The grievance will be investigated based on the details provided by the complainant
                                    and any internal
                                    records relevant to the issue. CareFinance will strive to resolve most grievances
                                    within 7 business days
                                    of receiving the complete details of the complaint. Complex cases that require
                                    further investigation may
                                    take longer. In such cases, the complainant will be informed of the extended time
                                    frame and provided
                                    with periodic updates on the status of the complaint.</p>
                            </li>
                            <li class="hide">
                                <p><strong>6.3. Final Response</strong></p>
                                <p>Once the investigation is completed, the complainant will be informed of the
                                    resolution via email, phone,
                                    or mail. The final response will include:</p>
                                <ul>
                                    <li>A detailed explanation of the resolution.</li>
                                    <li>Any corrective actions or compensations offered, if applicable.</li>
                                    <li>Information on the next steps, if the complainant is dissatisfied with the
                                        resolution (see Section
                                        7: Escalation of Grievances).</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Escalation of Grievances</p>
                        <ol>
                            <li class="hide">
                                <p><strong>7.1. Appeal to Senior Management</strong></p>
                                <p>The complainant may escalate the issue to the Senior Grievance Redressal Officer
                                    (SGRO) by sending an
                                    email to shrikant&#64;cipherventurecapital.com or via mail to:</p>



                                <p>Senior Grievance Redressal Officer,<br>FF- 6 A, Krishna Complex, Nr. Shayona
                                    Estate,<br>Memco Naroda Road, Ahmedabad,<br />Gujarat, India, 380025.

                                </p>
                                <p>The SGRO will review the escalated grievance and provide a final response within 10
                                    business days.</p>
                            </li>
                            <li class="hide">
                                <p><strong>7.2. Regulatory Escalation</strong></p>
                                <p>If the complainant remains dissatisfied with the resolution or if the grievance is
                                    not addressed within
                                    the time frame outlined in this Policy, they may approach the relevant regulatory
                                    body, including but
                                    not limited to:</p>
                                <ul>
                                    <li>Reserve Bank of India (RBI) (for grievances related to financial services and
                                        digital lending).</li>
                                    <li>Ombudsman for Digital Financial Services (where applicable).</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Roles and Responsibilities</p>
                        <ol>
                            <li class="hide">
                                <p><strong>8.1. Grievance Redressal Officer (GRO)</strong></p>
                                <ul>
                                    <li>Ensure that grievances are addressed promptly, fairly, and in compliance with
                                        the law.</li>
                                    <li>Coordinate with internal teams to investigate and resolve complaints.</li>
                                    <li>Communicate clearly with the complainant regarding the status and outcome of the
                                        grievance.</li>
                                    <li>Maintain records of all grievances, resolutions, and communications.</li>
                                </ul>
                            </li>
                            <li class="hide">
                                <p><strong>8.2. Senior Grievance Redressal Officer (SGRO)</strong></p>
                                <ul>
                                    <li>Handle escalated grievances.</li>
                                    <li>Ensure transparency and fairness in the grievance redressal process.</li>
                                    <li>Review and amend policies and processes based on the nature and frequency of
                                        complaints.</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Confidentiality</p>
                        <p>All grievances and personal details provided by the complainant will be handled with the
                            utmost confidentiality.
                            CareFinance will not disclose any personal or sensitive information to third parties without
                            the express consent
                            of the complainant, except where required by law.</p>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Monitoring and Reporting</p>
                        <p>CareFinance will maintain records of all grievances, including the nature of the complaint,
                            time taken for
                            resolution, and final outcomes. These records will be used to:</p>
                        <ol>
                            <ul>
                                <li>Monitor the effectiveness of the grievance redressal process.</li>
                                <li>Identify areas for improvement in products, services, or processes.</li>
                                <li>Report regularly to management on grievance trends and issues.</li>
                            </ul>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Policy Review and Updates</p>
                        <p>This Policy will be reviewed periodically to ensure its continued effectiveness and alignment
                            with regulatory
                            requirements. Any updates or changes to the Policy will be communicated to stakeholders and
                            customers through
                            appropriate channels.</p>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Conclusion</p>
                        <p>CareFinance is committed to resolving grievances in a timely, transparent, and fair manner.
                            By implementing this
                            Grievance Redressal Mechanism, CareFinance aims to maintain the trust of its customers and
                            stakeholders while
                            continuously improving its digital lending services.</p>
                    </li>






                </ol>
            </div>
        </div>
    </div>

</div>