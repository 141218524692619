import {
    Component, OnInit 
} from '@angular/core';

@Component({
    selector: 'app-interest-rate-policy',
    templateUrl: './Interest-rate-policy.component.html',
    styleUrls: [ './Interest-rate-policy.component.scss' ]
})
export class InterestRatePolicyComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }
}
