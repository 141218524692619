<div class="terms-condition-container">
    <div class="content">
        <!-- <div class="left-section">
            <div class="logo">
                <img src="https://assets.carefinance.xyz/Carewhitelogo.png" alt="Care Finance" title="Care Finance"
                    loading="lazy" />
            </div>
        </div> -->

        <div class="terms-content">
            <h1 class="heading">Terms and conditions</h1>
            <br>
            <br>
            <div class="paragraph">
                <p class="bold">Effective Date: 5/10/2024
                </p>
                <p>
                    Welcome to Cipherhut software India Pvt Ltd DBA <strong>Carefinance</strong> (“we,” “our,” or “us”).
                    These Terms and Conditions ("Terms") govern your
                    access to and use of our
                    mobile application ("App") and the services provided through it. By downloading, accessing, or using
                    our App, you agree
                    to these Terms. Please read them carefully.

                </p>
                <ol>
                    <li class="hide">
                        <p class="bold">
                            Acceptance of Terms
                        </p>
                        <p>
                            By using our services, you agree to be bound by these Terms, including any additional terms
                            and policies
                            referenced herein. If you do not agree to these Terms, you may not use our services.
                        </p>
                    </li>

                    <li class="hide">
                        <p class="bold">
                            Eligibility
                        </p>
                        <p>
                            To use the App and apply for a loan, you must:
                        </p>
                        <ol>
                            <li>
                                Be at least 18 years of age or the legal age of majority in your jurisdiction.
                            </li>
                            <li>
                                Be a resident of a country where our services are offered.
                            </li>
                            <li>
                                Provide accurate, complete, and up-to-date information during the loan application
                                process.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li class="hide">
                        <p class="bold">
                            Changes to Terms
                        </p>
                        <p>
                            We may update these Terms of Use from time to time. Any changes will be posted on this page
                            with a new effective
                            date. We encourage you to review these Terms regularly to stay informed about any updates.
                            Your continued use of
                            the Services after any modifications constitutes your acceptance of the revised Terms.
                        </p>
                    </li>

                    <li class="hide">
                        <p class="bold">
                            Account Registration
                        </p>
                        <p>
                            To access certain features of the App and Website, you may need to register for an account.
                            You agree to provide
                            accurate, current, and complete information during the registration process and to update
                            such information to
                            keep it accurate, current, and complete.
                        </p>
                        <p>
                            You are responsible for maintaining the confidentiality of your account information and for
                            all activities that
                            occur under your account. If you suspect any unauthorized use of your account, you must
                            notify us immediately.
                        </p>
                    </li>

                    <li class="hide">
                        <p class="bold">
                            Use of Services
                        </p>
                        <p>
                            You agree to use the Services only for lawful purposes and in accordance with these Terms.
                            You must not use the
                            App or Website:
                        </p>
                        <ol>
                            <li>
                                In any way that violates any applicable laws or regulations.
                            </li>
                            <li>
                                To engage in any conduct that restricts or inhibits anyone’s use or enjoyment of the
                                Services.
                            </li>
                            <li>
                                To impersonate or attempt to impersonate [Your Company Name], a company employee,
                                another user, or any other
                                person or entity.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">Loan Application Process</p>
                        <p>
                            Our platform allows users to apply for healthcare loans directly through the App. By
                            submitting a loan
                            application, you consent to our collection and processing of your personal and financial
                            information, as
                            outlined in our Privacy Policy.
                        </p>
                        <p>
                            You understand that the loan application process may involve checks regarding your credit
                            score and other
                            financial assessments. The final decision regarding loan approval is at the discretion of
                            our partner lending
                            institutions.
                        </p>
                    </li>
                    <br />
                    <li>
                        <p class="bold">Loan Disbursement</p>
                        <p>
                            If your loan application is approved, the loan amount will be disbursed directly to the
                            hospital or healthcare
                            provider for your treatment costs. You are responsible for repaying the loan according to
                            the terms specified in
                            your loan agreement.
                        </p>
                    </li>
                    <li>
                        <p class="bold">Intellectual Property Rights</p>
                        <p>
                            All content, features, and functionalities on the App and Website, including but not limited
                            to text, graphics,
                            logos, and software, are the exclusive property of Carefinance or our licensors and are
                            protected by copyright,
                            trademark, and other intellectual property laws. You may not reproduce, distribute, modify,
                            or create derivative
                            works without our express written consent.
                        </p>
                    </li>
                    <li>
                        <p class="bold">Services Provided</p>
                        <p>
                            We provide users with the ability to apply for healthcare-related loans directly through our
                            App. Upon approval,
                            loan amounts are disbursed directly to the hospital to cover treatment costs, and users are
                            responsible for
                            repaying the loan according to the agreed-upon terms.
                        </p>
                        <ol>
                            <li>
                                <p class="bold">Loan Application Process</p>
                                <p>
                                    You may apply for a loan through our App by providing the required personal,
                                    financial, and medical
                                    information.
                                    We will assess your creditworthiness using third-party credit bureaus and financial
                                    institutions.
                                    Loan approval is subject to meeting our credit criteria, and approval is not
                                    guaranteed.
                                </p>
                            </li>
                            <li>
                                <p class="bold">Loan Disbursement</p>
                                <p>
                                    Loan disbursements are made directly to the hospital or healthcare provider where
                                    you are receiving
                                    treatment.
                                    You are responsible for ensuring that the loan amount covers your treatment costs
                                    and any additional
                                    fees charged by the hospital.
                                </p>
                            </li>
                            <li>
                                <p class="bold">Loan Repayment</p>
                                <p>
                                    You agree to repay the loan based on the terms specified in your loan agreement,
                                    including interest
                                    rates, repayment schedule, and any applicable fees.
                                    Failure to make timely payments may result in penalties, additional interest, and
                                    negative impacts on
                                    your credit score.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">User Responsibilities</p>
                        <p>
                            As a user of our services, you agree to:
                        </p>
                        <ol>
                            <ul>
                                <li>Provide truthful, accurate, and up-to-date information during the loan application
                                    process.</li>
                                <li>Use the services only for lawful purposes.</li>
                                <li>Ensure that you have the legal right to apply for the loan and agree to be bound by
                                    the
                                    loan agreement.</li>
                                <li>Repay the loan according to the terms outlined in your loan agreement.</li>
                            </ul>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">Privacy and Data Collection</p>
                        <p>
                            We collect and process personal information in accordance with our Privacy Policy. By using
                            our services, you
                            agree to the collection and use of your information as outlined in our Privacy Policy.
                        </p>
                    </li>
                    <li>
                        <p class="bold">Disclaimers</p>
                        <ol>
                            <li>
                                <p class="bold">No Guarantee of Loan Approval</p>
                                <p>
                                    We do not guarantee the approval of any loan application. Approval is subject to
                                    various factors,
                                    including your creditworthiness, financial history, and the policies of the
                                    third-party institutions we
                                    work with.
                                </p>
                            </li>
                            <li>
                                <p class="bold">No Medical Advice</p>
                                <p>
                                    Our services are limited to financial assistance for healthcare treatment costs. We
                                    do not provide any
                                    medical advice, diagnoses, or treatment recommendations. You are solely responsible
                                    for your healthcare
                                    decisions.
                                </p>
                            </li>
                            <li>
                                <p class="bold">No Warranty</p>
                                <p>
                                    Our services are provided on an "as-is" and "as-available" basis. We make no
                                    representations or
                                    warranties of any kind, express or implied, regarding the availability, accuracy, or
                                    reliability of our
                                    App or services.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">Limitation of Liability</p>
                        <p>
                            To the fullest extent permitted by law, we shall not be liable for any direct, indirect,
                            incidental, special,
                            consequential, or punitive damages arising out of or related to your use of our services,
                            including but not
                            limited to:
                        </p>
                        <ol>
                            <ul>
                                <li>Loan application or disbursement errors.</li>
                                <li>Medical treatment decisions.</li>
                                <li>Financial loss or credit score impacts resulting from loan approval or denial.</li>
                            </ul>
                        </ol>
                        <p>
                            Our total liability to you for any claim related to the use of our services shall not exceed
                            the amount of the
                            loan applied for or received through our platform.
                        </p>
                    </li>
                    <li>
                        <p class="bold">Third-Party Services</p>
                        <p>
                            Our App may integrate with or link to third-party services, such as credit bureaus and
                            financial institutions.
                            We do not control these third-party services and are not responsible for their content,
                            privacy practices, or
                            performance. By using such third-party services, you agree to their respective terms and
                            conditions.
                        </p>
                    </li>
                    <li>
                        <p class="bold">Modifications to Services and Terms</p>
                        <p>
                            We reserve the right to modify, suspend, or discontinue any aspect of our services or these
                            Terms at any time.
                            If changes are made, we will notify you by posting the updated Terms in the App. Continued
                            use of our services
                            after any modifications constitutes acceptance of the new Terms.
                        </p>
                    </li>
                    <li>
                        <p class="bold">Termination</p>
                        <p>
                            We may terminate or suspend your access to our services at any time, without notice or
                            liability, for any
                            reason, including but not limited to:
                        </p>
                        <ul>
                            <li>Breach of these Terms.</li>
                            <li>Providing false or misleading information during the loan application process.</li>
                            <li>Failure to comply with loan repayment obligations.</li>
                        </ul>
                        <p>
                            Upon termination, you must immediately cease using the App and any outstanding loans will
                            remain your
                            responsibility under the terms of your loan agreement.
                        </p>
                    </li>
                    <li>
                        <p class="bold">Refund and Cancellation Policy</p>
                        <p>
                            Any overpayment made by you shall be refunded back within 30 days pursuant to the
                            reconciliation of the accounts
                            by the company. There will be no cancellation of the service (except in case of payment
                            during the look-up
                            period) once you have received the loan amount in your bank account.
                        </p>
                    </li>
                    <li>
                        <p class="bold">Governing Law and Dispute Resolution</p>
                        <p>
                            These Terms shall be governed by and construed in accordance with the laws of Pune,
                            Maharashtra. Any disputes
                            arising out of or in connection with these Terms or the use of our services shall be
                            resolved through
                            arbitration in accordance with the rules of Pune, Maharashtra.
                        </p>
                    </li>
                    <li>
                        <p class="bold">Limitation of Liability and Injunctive Relief</p>
                        <ol>
                            <li>
                                <p>
                                    The Visitor agrees that in no event will Carefinance or any of its affiliates or
                                    group companies or any
                                    of their respective officers, employees, directors, shareholders, agents, or
                                    licensors be liable to the
                                    Visitor or anyone else under any theory of liability (whether in contract, tort,
                                    statutory, or
                                    otherwise) for any direct, indirect, incidental, special, consequential or exemplary
                                    damages, including
                                    damages for loss of revenues, profits, goodwill, use, data or other intangible
                                    losses (even if such
                                    parties were advised of, knew of or should have known of the possibility of such
                                    damages), resulting
                                    from the Visitor’s use (or the use of anyone using an account registered to the
                                    Visitor) of the App or
                                    any parts thereof.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Notwithstanding anything to the contrary herein, the Visitor hereby irrevocably
                                    waives any right or
                                    remedy to seek and/or obtain injunctive or other equitable relief or any order with
                                    respect to, and/or
                                    to enjoin or restrain or otherwise impair in any manner, the production,
                                    distribution, exhibition or
                                    other exploitation of any Carefinance or any of its affiliate or group company
                                    related project, or the
                                    use, publication or dissemination of any advertising in connection with such
                                    project.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">Force Majeure</p>
                        <p>
                            Carefinance shall not be liable if the performance of any of its obligations under the Terms
                            and Conditions is
                            prevented, hindered or delayed by a Force Majeure Event (defined below), and, in such case,
                            Carefinance’s
                            obligations shall be suspended until the Force Majeure Event ends.
                        </p>
                        <p>
                            For this purpose, “Force Majeure Event” means any event due to any cause beyond the
                            reasonable control of any
                            party, including unavailability of any communication system, breach or virus in the systems,
                            fire, flood,
                            explosion, acts of God, civil commotion, riots, insurrection, war, acts of government.
                        </p>
                    </li>
                    <li>
                        <p class="bold">Severability</p>
                        <p>
                            If any provision of these Terms and Conditions is determined to be invalid or unenforceable
                            in whole or in part,
                            such invalidity or unenforceability shall attach only to such provision or part of such
                            provision and the
                            remaining part of such provision and all other provisions of these Terms and Conditions
                            shall continue to be in
                            full force and effect.
                        </p>
                    </li>
                    <li>
                        <p class="bold">Notices</p>
                        <ol>
                            <li>
                                <p>
                                    All notices and communications from the Visitor to Carefinance in relation to the
                                    App and/or any of the
                                    Services (including the termination of any of the Services etc.) shall be in
                                    writing. The Visitor shall
                                    be deemed to have been given a notice if sent by an email, SMS or posted within the
                                    App.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Notice shall be deemed to have been served 48 hours after it has been sent,
                                    dispatched, displayed, as
                                    the case may be, unless, where notice has been sent by email, it comes to the
                                    knowledge of the sending
                                    party that the email address is invalid.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">Entire Agreement</p>
                        <p>
                            These Terms and Conditions constitute the entire agreement between Carefinance and the
                            Visitor and supersedes
                            and extinguishes all previous agreements, promises, assurances, representations, warranties
                            and undertakings,
                            whether written or oral.
                        </p>
                    </li>
                    <li>
                        <p class="bold">Contact Information</p>
                        <p>
                            If you have any questions or concerns regarding these Terms or our services, please contact
                            us at: shrikant&#64;cipherventurecapital.com.
                        </p>
                    </li>


                </ol>
            </div>
        </div>
    </div>
</div>