<div class="app-our-partners">
    <div class="section-1">
        <div class="background">
            <div class="container">
                <!-- <div class="header-container">
                    <img class="care-logo" src="https://assets.carefinance.xyz/Careblacklogo.png" alt="Care Finance"
                        title="Care Finance" />

                    <h1 (click)="scrollToSection()" class="title">FAQ</h1>
                </div> -->
                <div class="main-container">
                    <div class="text-content">
                        <h2 class="title-white">Our Partners</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="section-2">
        <div class="container">
            <ol>
                <li>
                    <div class="address">
                        <h2>GEL Credit</h2>
                        <p>Bhive Workspace, No.467/4 Shri Krishna Temple Rd,
                            <br />Indiranagar, Bengaluru, Karnataka,
                            India
                            560038
                        </p>
                        <p>+91 95183 76451</p>
                    </div>
                </li>
                <li>
                    <div class="address">
                        <h2>Medikash</h2>
                        <p>9th Main, 7th Sector, HSR Layout,
                            <br />Bangalore, Karnataka, India, 560102
                        </p>

                    </div>

                </li>
                <li>
                    <div class="address">
                        <h2>Western Fintrade pvt ltd</h2>
                        <p>Western Fintrade pvt ltd,
                            FF- 6 A, Krishna Complex, Nr. Shayona Estate, Memco Naroda Road, Ahmedabad, Gujarat, India,
                            380025
                        </p>
                        <p>+91 95183 76451</p>

                    </div>
                </li>
            </ol>
        </div>
    </div>
</div>