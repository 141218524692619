<div class="interest-rate-policy-container">

    <div class="content">
        <!-- <div class="left-section">
            <div class="logo">
                <img src="https://assets.carefinance.xyz/Carewhitelogo.png" alt="Care Finance" title="Care Finance"
                    loading="lazy" />
            </div>
        </div> -->

        <div class="privacy-content">

            <h1 class="heading">Interest Rate Policy</h1>
            <br>
            <br>
            <div class="paragraph">





                <ol>

                    <li>
                        <p class="bold">
                            Introduction
                        </p>
                    </li>
                    <p>
                        This Interest Rate Policy (hereinafter referred to as “Policy”) taking into account relevant
                        factors such as cost of
                        funds,margin and risk premium, etc. The Policy has been duly approved by its Board of Directors.
                        This Policy shall be
                        read with the Fair Practices Code of the Company. In addition to cost factors set out
                        here-under, the Board shall be
                        guided by the market conditions and various rules and regulations, if any, prescribed by the RBI
                        or such other authority
                        from time to time.


                    </p>
                    <br />


                    <li>

                        <p class="bold">
                            Definations
                        </p>
                        <ol>
                            <li>
                                ALCO Shall mean the Asset Liability Committee formed by the Company

                            </li>
                            <li>
                                Board Shall mean the Board of Directors of the Company


                            </li>
                            <li>
                                Company Shall mean Cipherhut Software India Pvt. Ltd. DBA Carefinance

                            </li>
                            <li>
                                Policy Shall mean this Interest Rate Policy formulated by the Company.

                            </li>
                            <li>Customer Shall mean any person(s) who is/are engaged or intending to engage in a
                                financial transaction or activity with
                                the Company and includes a person(s) on whose behalf such the person(s) who is/are
                                engaged in the financial transaction
                                or activity, is/are acting.
                            </li>
                            <li>SBR Directions Shall mean Master Direction – Reserve Bank of India (Non-Banking
                                Financial Company – Scale Based
                                Regulation) Directions, 2023
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li>
                        <p class="bold">
                            Purpose
                        </p>
                        <p>In line with the SBR Directions referred to above, this Policy shall define the
                            parameters for
                            determining interest rates and other charges for different categories of Customers.</p>
                    </li>

                    <li>
                        <p class="bold">Interest Rate Methodology and Gradation of Risk</p>

                        <ol>
                            <li class="hide">
                                <p>4.1. Carefinance charges a fixed rate of interest on its loan products and does not
                                    charge a floating
                                    rate of interest. The tenor of the loans granted by Carefinance is very short, and
                                    hence the Company may
                                    define a certain period as a lock-in period to ensure adequate return on the loan
                                    facility.</p>
                            </li>

                            <li class="hide">
                                <p>4.2. Carefinance has its own machine-learning model for calculating interest
                                    rates,
                                    taking into
                                    consideration Carefinance’s cost of funds, overhead and administrative costs,
                                    expected return on capital
                                    employed, and risk premium. The interest rate will be arrived at after
                                    considering
                                    the following
                                    aspects:</p>

                                <ol>
                                    <li class="hide">
                                        <p><strong>i. Cost of Borrowing:</strong> The first element of the interest
                                            rate
                                            is the cost of
                                            borrowing of the Company, which includes interest and incidental charges
                                            payable for servicing
                                            borrowed funds deployed by the Company. This also includes the rates
                                            charged
                                            by any co-lending
                                            partner in any co-lending partnership arrangement.</p>
                                    </li>

                                    <li class="hide">
                                        <p><strong>ii. Return on Capital Employed:</strong> The second element is
                                            the
                                            expected return on
                                            capital employed, which the Company aims to generate for servicing the
                                            owners' capital employed
                                            in the business.</p>
                                    </li>

                                    <li class="hide">
                                        <p><strong>iii. Overhead Costs:</strong> The third element influencing the
                                            interest rate is the
                                            overhead/sourcing cost incurred for sourcing and processing the loan
                                            application, including but
                                            not limited to employee costs, office expenses, insurance premium,
                                            marketing
                                            expenses, etc.</p>
                                    </li>
                                </ol>
                            </li>

                            <li class="hide">
                                <p>4.3. The interest rate is the summation of the cost of borrowing,
                                    overhead/sourcing
                                    costs, expected
                                    return on capital employed, and risk premium. Based on the customer's risk
                                    profile
                                    and loan tenor, the
                                    Company ensures optimal lock-in periods. The lock-in period is not mandatory and
                                    may
                                    be decided by the
                                    Board, depending on market conditions.</p>
                            </li>

                            <li class="hide">
                                <p>4.4. The interest rate for various loans is calculated based on loan tenor,
                                    ticket
                                    size, and risk
                                    calculations. The applicable interest rate for each loan account will be
                                    determined
                                    on a case-by-case
                                    basis after evaluating various factors such as:</p>

                                <ol>
                                    <li class="hide">
                                        i. Structure of the deal</li>
                                    <li class="hide">
                                        ii. Interest rate trend prevailing in the money market</li>
                                    <li class="hide">
                                        iii. Nature of lending (secured/unsecured) and associated tenure</li>
                                    <li class="hide">
                                        iv. Risk profile of the customer (professional qualifications, earnings
                                        stability,
                                        employment)</li>
                                    <li class="hide">
                                        v. Risk premium, keeping in mind inherent credit and default risks</li>
                                    <li class="hide">
                                        vi. Long-term prospects of business with the customer</li>
                                    <li class="hide">
                                        vii. Past repayment track record</li>
                                    <li class="hide">
                                        viii. Loan-specific costs</li>
                                    <li class="hide">
                                        ix. Company’s cost of borrowings</li>
                                    <li class="hide">
                                        x. Customer negotiations/deviations</li>
                                    <li class="hide">
                                        xi. Industry trends and competition</li>
                                    <li class="hide">
                                        xii. Upfront charges</li>
                                    <li class="hide">
                                        xiii. Outsourcing costs</li>
                                </ol>
                            </li>

                            <li class="hide">
                                <p>4.5. The interest rate is further adjusted based on the risk profile of the
                                    customer
                                    by factoring in a
                                    risk premium, which can either increase or decrease the interest rate. The
                                    computation of the risk
                                    premium is described below:</p>

                                <ol>
                                    <li class="hide">
                                        <p><strong>4.5.1. Calculation of Risk Premium:</strong> The risk premium is
                                            determined by
                                            considering the Company’s minimum margin and the degree of risk
                                            associated
                                            with the loan, based
                                            on factors such as general economic conditions, customer category,
                                            repayment
                                            capacity,
                                            loan-to-value ratio, tenure of the loan, and past repayment history.
                                            Customers with lower
                                            perceived risk will have a lower risk premium, while higher-risk
                                            customers
                                            will have a higher
                                            risk premium.</p>
                                    </li>

                                    <li class="hide">
                                        <p><strong>4.5.2. Credit Tiering and Risk-Based Pricing:</strong>
                                            Carefinance
                                            may use various credit
                                            tiering systems for risk-based pricing. The Company offers preferential
                                            rates to repeat
                                            customers with positive credit behavior, but reserves the right to
                                            adjust
                                            terms for new loans
                                            based on portfolio performance or macroeconomic conditions.</p>
                                    </li>

                                    <li class="hide">
                                        <p><strong>4.5.3. Pricing Across Loan Terms:</strong> Loan pricing varies
                                            based
                                            on individual
                                            customer credit profiles. Carefinance maintains updated loan terms and
                                            rate
                                            schedules, which may
                                            change due to macroeconomic, political, or regulatory factors.</p>
                                    </li>
                                </ol>
                            </li>

                            <li class="hide">
                                <p>4.6. The interest rate for the same product and tenor availed during the same
                                    period
                                    by different
                                    customers need not be standardized. The final lending rate applicable to each
                                    customer will be assessed
                                    based on the factors outlined in this Policy, using Carefinance’s model.</p>
                            </li>

                            <li class="hide">
                                <p>4.7. Manner of Payment: Carefinance may offer payment schedules of varying
                                    frequencies based on internal
                                    risk ratings and/or a customer's repayment history. Loan schedules may require
                                    monthly payments, and the
                                    frequency will be part of the loan terms displayed to the customer before loan
                                    funding. The payment
                                    frequency cannot be altered during the loan’s term. Interest payments are
                                    included
                                    in the overall
                                    payment schedule and cannot be changed.</p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">Processing and Other Charges</p>

                        <ol>
                            <li class="hide">
                                <p>5.1. Besides interest, Carefinance may also levy other charges such as processing or
                                    origination fees,
                                    cheque bouncing charges, pre-payment charges, foreclosure charges, part disbursement
                                    charges, penal
                                    charges, cheque swaps, remittance charges, commitment fees, convenience fees, and
                                    charges for various
                                    other services like issuing ‘No Due Certificates’, NOCs, etc. These charges may be
                                    levied wherever
                                    considered necessary and shall be determined by the credit committee and approved by
                                    the Board.</p>
                            </li>

                            <li class="hide">
                                <p>5.2. Carefinance may levy additional late fees, penal charges, or extension fees to
                                    Customers who do not
                                    repay their loans on time. These fees shall be communicated to the Customers prior
                                    to loan application
                                    submission and mentioned in bold letters in the Key Fact Statement, sanction letter,
                                    and other loan
                                    documents. Penal charges shall be levied in accordance with RBI guidelines and the
                                    Company’s Fair
                                    Practices Code.</p>
                            </li>

                            <li class="hide">
                                <p>5.3. All such charges shall be clearly communicated to the Customer through the Key
                                    Fact Statement (KFS),
                                    application form, sanction letter, and loan agreement, as further explained under
                                    the 'Disclosures'
                                    section. Any revision in these charges shall be applied prospectively and
                                    communicated to Customers.</p>
                            </li>

                            <li class="hide">
                                <p>5.4. All loans that are pre-paid shall bear prepayment penalties at the rates
                                    mentioned in the respective
                                    Customer loan agreements. However, Customers who exit the digital loan in accordance
                                    with the
                                    cooling-off period prescribed in the Loan Policy shall not be charged prepayment
                                    penalties. In any case,
                                    if the Customer opts to exit the digital loan, a proportionate APR shall be
                                    chargeable.</p>
                            </li>

                            <li class="hide">
                                <p>5.5. Carefinance shall ensure that any fees, charges, etc., payable to any lending
                                    service
                                    provider/third-party agent (LSP) are paid directly by Carefinance and are not
                                    charged by LSP to the
                                    Customer. Processing, documentation, and other charges should be expressly stated in
                                    the KFS and Loan
                                    Agreement. Carefinance may levy the following fees and charges to the borrowers:</p>

                                <ul>
                                    <li><strong>Loan Processing Charges:</strong> Charged to the borrower for expenses
                                        related to
                                        documentation, agreement, due diligence, credit appraisal, and acquisition
                                        costs.</li>
                                    <li><strong>Delayed Payment Charges:</strong> Charged on unpaid dues when the
                                        borrower defaults on the
                                        EMI due date.</li>
                                    <li><strong>Part Prepayment Charges:</strong> Charged on the prepaid amount, as may
                                        be decided from time
                                        to time.</li>
                                    <li><strong>Foreclosure Charges:</strong> Charged on the principal outstanding at
                                        the time of
                                        foreclosure, as decided from time to time.</li>
                                    <li><strong>Cheque/NACH Bouncing Charges:</strong> Charged for each instance a
                                        cheque is dishonoured, or
                                        for missed payments under any payment modes, including ECS or direct debit
                                        methods.</li>
                                    <li>
                                        <strong>Loan Recovery Charge:</strong> Charged for expenses incurred by
                                        Carefinance to recover dues,
                                        including legal fees, duties, notices, advertisements, etc.
                                    </li>
                                </ul>

                                <p>The aim of these charges, aside from Loan Processing Charges, is to encourage timely
                                    repayment and deter
                                    intentional delinquency by borrowers.</p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">General</p>

                        <ol>
                            <li class="hide">
                                <p>6.1. <strong>Nature of Interest:</strong> Carefinance may elect to offer fixed or
                                    variable interest rates
                                    to Customers, with varying terms and repayment structures, based on the default risk
                                    of individual
                                    Customers and the broader Carefinance loan portfolio.</p>
                            </li>

                            <li class="hide">
                                <p>6.2. For fixed interest rates, the spread would be computed based on product-specific
                                    and
                                    Customer-specific parameters, as determined by the Credit Committee/Board. Fixed
                                    interest rates shall
                                    not be reset and will remain fixed.</p>
                            <li class="hide">

                            <li>
                                <p>6.3. The interest rate and all other applicable charges shall be communicated to the
                                    Customers prior to
                                    the submission of the loan application.</p>
                            </li>

                            <li class="hide">
                                <p>6.4. Carefinance may choose not to entertain claims regarding refund or waiver of
                                    charges/penal charges,
                                    and it shall be at the sole discretion of the Company to handle such requests.</p>
                            </li>

                            <li class="hide">
                                <p>6.5. Any fees, charges, etc., that are not mentioned in the Key Fact Statement (KFS)
                                    shall not be charged
                                    by Carefinance to the Customer at any stage during the term of the loan.</p>
                            </li>

                            <li class="hide">
                                <p>6.6. In addition to the loan amount sanctioned, the Company shall convey to the
                                    Customer, in a language
                                    understood by the Customer, the annualized rate of interest. This shall be
                                    communicated via the
                                    Carefinance app or otherwise and kept on record.</p>
                            </li>

                            <li class="hide">
                                <p>6.7. The sanctioning authority shall record specific reasons in writing at the time
                                    of sanctioning a
                                    demand or call loan if no interest is stipulated or a moratorium is granted for any
                                    period.</p>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <p class="bold">Disclosures</p>
                        <p>The Company shall, along with the disclosures prescribed under applicable laws, ensure the
                            following:</p>

                        <p>The Customer shall be informed of any changes in terms and conditions, including changes in
                            interest rates,
                            service charges, prepayment fees, etc. Such intimation of alterations in interest rates or
                            other fees will be
                            conveyed to Customers in the manner specified in the loan documents.</p>
                    </li>

                    <li>
                        <p class="bold">Review of Policy</p>
                        <p>The Policy shall be reviewed by the Credit Committee of Carefinance on an annual basis, and
                            any changes shall be
                            approved by the Company’s Board of Directors.</p>
                    </li>






                </ol>
            </div>
        </div>
    </div>

</div>